import { useState, useEffect } from "react";
import api from "../../../utils/api";

const BannerDetails = () => {
  const [bannerData, setBannerData] = useState({
    banquets: 8,
    cities: 15,
  });
  const getBannerData = async () => {
    try {
      const { data } = await api.get("/user/totalBanquets");
      setBannerData({
        banquets:
          data.totalBanquets > bannerData.banquets
            ? Math.ceil(data.totalBanquets / 5) * 5
            : bannerData.banquets,
        cities:
          data.totalCities > bannerData.cities
            ? Math.ceil(data.totalCities / 5) * 5
            : bannerData.cities,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBannerData();
  }, []);

  return (
    <div className="home-banner-details">
      <div className="flex justify-center flex-col">
        <h2 className="font-bold">
          Introducing Our New and Exciting Banquet Service
        </h2>
        <p className="font-medium color-black">
          Experience the perfect blend of elegance and affordability with our
          brand new banquet service. We are thrilled to provide you with an
          affordable, easy, and convenient solution for all your special
          occasions. Whether it's an intimate gathering or a grand celebration,
          we have the perfect venue for you.
        </p>
        <div className="mt-10">
          <h4 className="mb-2 hide-on-mobile">
            We are proud to announce that we have over
          </h4>
          <div className="flex gap-12 stats">
            <div>
              <h2 className="color-red">{bannerData.cities}+ </h2>
              <h3>Cities</h3>
            </div>
            <div>
              <h2 className="color-red">{bannerData.banquets}+</h2>
              <h3>Exclusive Banquets</h3>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/assets/banquet-group.png"
        alt="banner"
        className="h-[28rem] w-[28rem]"
      />
    </div>
  );
};

export default BannerDetails;
