import React from "react";

const ReviewSpan = ({ rating }) => {
  if (rating > 4.5)
    return <div className="p-2 text-center w-full color-white font-semibold bg-green-500">Excellent</div>;
  if (rating > 4.0)
    return <div className="p-2 w-full text-center color-white font-semibold bg-green-500">Very Good</div>;
  if (rating > 3.5)
    return <div className="p-2 w-full text-center color-darkgrey font-semibold bg-green-200">Good</div>;
  if (rating > 3.0)
    return <div className="p-2 w-full text-center color-darkgrey font-semibold bg-yellow-200">Average</div>;
  if (rating > 2.5)
    return <div className="p-2 w-full text-center color-darkgrey font-semibold bg-yellow-500">Below Average</div>;
  if (rating > 2.0)
    return <div className="p-2 w-full text-center color-darkgrey font-semibold bg-orange-200">Poor</div>;
  if (rating > 1.5)
    return <div className="p-2 w-full text-center color-darkgrey font-semibold bg-orange-500">Very Poor</div>;
  if (rating > 1.0)
    return <div className="p-2 w-full text-center color-white font-semibold bg-red-500">Terrible</div>;
};

export default ReviewSpan;
