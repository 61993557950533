import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../utils/api";
import BanquetCard, { PlaceholderCard } from "../../../common/BanquetCard";
import Button from "../../../common/Button";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const TopBanquetList = () => {
  const [topBanquets, setTopBanquets] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTopBanquets = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/user/topBanquets");
      console.log(data);
      setTopBanquets(data.topBanquetHalls);
    } catch (err) {
      console.error(err.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getTopBanquets();
  }, []);
  return (
    <section className="top-banquets">
      <div className="mb-4">
        <h1 className="text-center mb-2">Our Top Banquets</h1>
        <p className="text-center color-black">
          Make your function more impressive with Banquet Near Me
        </p>
      </div>
      <div className="flex flex-wrap gap-x-8 gap-y-16 my-16 justify-center">
        {loading
          ? [1, 2, 3, 4, 5].map((i) => <PlaceholderCard key={i} />)
          : topBanquets &&
            topBanquets.map((banquet, index) => (
              <BanquetCard {...banquet} key={index} index={index}>
                <div className="text-sm flex items-center gap-2 font-semibold color-blue">
                  View Details
                  <MdKeyboardDoubleArrowRight />
                </div>
              </BanquetCard>
            ))}
      </div>
      <div className="flex justify-center">
        <Link to="/listings?showTopBanquets=true">
          <Button
            theme="red"
            rounded="full"
            size="lg"
            className="font-semibold"
          >
            Find Out more
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default TopBanquetList;
