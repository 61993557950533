import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../utils/api";
import BanquetCard from "../../../common/BanquetCard";
import Button from "../../../common/Button";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useSearchSuggestions } from "../../../utils/useSearchSuggestions";
import distanceBetweenCoordinates from "../../../utils/distanceBetweenCoordiates";

const BanquetNearYou = ({ lat, long }) => {
  const [nearbyBanquets, setNearbyBanquets] = useState([]);
  const getBanquets = async () => {
    let validPlace =
      searchSuggestions[0].city ||
      searchSuggestions[0].village ||
      searchSuggestions[0].town;
    if (validPlace === undefined && searchSuggestions[0].name === undefined)
      return console.log("No nearby banquets found", searchSuggestions[0]);
    try {
      const { data } = await api.post("/user/searchBanquets", {
        data: {
          locality: searchSuggestions[0].name,
          city: validPlace,
          people: 50,
          occasion: "wedding",
        },
      });
      console.log(data);
      // limit to 4 banquets
      if (data.result.length > 4) data.result.length = 4;
      // fill remaining by data.suggestions for a total of max 4
      if (data.result.length < 4) {
        data.result = [
          ...data.result,
          ...data.suggestions.slice(0, 4 - data.result.length),
        ];
      }
      // add distance to each banquet
      data.result.forEach((banquet) => {
        banquet.distance = distanceBetweenCoordinates(
          lat,
          long,
          banquet.coordinates.lat,
          banquet.coordinates.long
        );
      });
      setNearbyBanquets(data.result);
    } catch (err) {
      console.error(err.message);
    }
  };
  const [searchSuggestions] = useSearchSuggestions(`${lat}, ${long}`);
  useEffect(() => {
    if (searchSuggestions[0]?.label && lat && long) getBanquets();
  }, [searchSuggestions, lat, long]);

  if ((!lat && !long) || nearbyBanquets.length === 0) return null;

  return (
    <section className="top-banquets">
      <div className="mb-4">
        <h1 className="text-center mb-2">Banquets Near You</h1>
        <p className="text-center color-black">
          Get the best banquet halls near you
        </p>
      </div>
      <div className="flex flex-wrap gap-x-8 gap-y-16 my-16 justify-center">
        {nearbyBanquets &&
          nearbyBanquets.map((banquet, index) => (
            <div key={index} className="relative">
              {banquet.distance && (
                <div className="banquet-card-badge">
                  {banquet.distance}km away
                </div>
              )}
              <BanquetCard {...banquet} index={index}>
                <div className="text-sm flex items-center gap-2 font-semibold color-blue">
                  View Details
                  <MdKeyboardDoubleArrowRight />
                </div>
              </BanquetCard>
            </div>
          ))}
      </div>
      <div className="flex justify-center">
        <Link to={`/listings?city=${searchSuggestions[0]?.city}`}>
          <Button
            theme="red"
            rounded="full"
            size="lg"
            className="font-semibold"
          >
            List more
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default BanquetNearYou;
