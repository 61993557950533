import { Helmet } from "react-helmet";
import MembershipCard from "./components/MembershipCard";

const Membership = () => {
  return (
    <div className="banner-form-page-layout membership">
      <Helmet>
        <title>Banquet Near Me | Membership</title>
        <meta
          name="description"
          content="Join Banquet Near Me as a member. Gain access to exclusive features, reach a wider audience, and grow your banquet business exponentially."
        />
      </Helmet>
      <div className="banner-tagline">
        <h1 className="text-white">
          Super charge your business by making{" "}
          <span className="font-bold color-yellow">Banquet Near Me</span> your
          growth partner!
        </h1>
        <p className="text-white font-medium my-8 text-lg">
          A trusted choice for{" "}
          <span className="font-bold color-yellow">Banquets</span> across India.
        </p>
      </div>
      <MembershipCard />
    </div>
  );
};

export default Membership;
