import { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import PassContext from "../../../utils/PassContext";
import Button from "../../../common/Button";
import api from "../../../utils/api";

const OTP = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (state === undefined || state === null) navigate("/auth/register");
  }, []);
  const inputOtp = useRef(null);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [msg, setMsg] = useState({
    msg: "",
    type: "",
  });
  const focusInput = (ref) => {
    ref.current.select();
  };

  const handleResend = async () => {
    try {
      const { data } = await api.get("/user/resendOtp/" + state?.id);
      console.log(data);
      setMsg({
        msg: data.message,
        type: "success",
      });
    } catch (err) {
      console.log(err);
      setMsg({
        msg: err?.response?.data?.message || "Something went wrong",
        type: "error",
      });
    }
  };
  const { setLoggedUser } = useContext(PassContext);
  const [loading, setLoading] = useState(false);
  const handleOtpVerification = async () => {
    setLoading(true);
    try {
      const { data } = await api.post("/user/verify", {
        data: {
          otp: otp.join(""),
          id: state?.id,
        },
      });
      console.log(data);
      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      const decodedToken = jwtDecode(data.token);
      setLoggedUser({
        id: decodedToken.id,
        role: decodedToken.role,
      });
      navigate("/");
    } catch (err) {
      console.log(err);
      setMsg({
        msg: err?.response?.data?.message || "Something went wrong",
        type: "error",
      });
    }
    setLoading(false);
  };

  const inputOtp0 = useRef(null);
  const inputOtp1 = useRef(null);
  const inputOtp2 = useRef(null);
  const inputOtp3 = useRef(null);
  const inputOtp4 = useRef(null);
  const inputOtp5 = useRef(null);

  const getRef = (digit) => {
    switch (digit) {
      case 0:
        return inputOtp0;
      case 1:
        return inputOtp1;
      case 2:
        return inputOtp2;
      case 3:
        return inputOtp3;
      case 4:
        return inputOtp4;
      case 5:
        return inputOtp5;
      default:
        return null;
    }
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (otp.length < 6) {
          setMsg({
            msg: "Please enter a valid OTP",
            type: "error",
          });
          focusInput(inputOtp);
        } else handleOtpVerification();
      }}
    >
      <h3>
        OTP Verificaton
        <p className="color-grey text-sm">
          Enter the 6-digit code sent to you at {state?.email}
        </p>
      </h3>
      <div className="my-6">
        <div className="flex justify-evenly">
          {[0, 1, 2, 3, 4, 5].map((digit) => (
            <input
              key={digit}
              type="text"
              className="otp-box"
              maxLength={1}
              ref={getRef(digit)}
              value={otp[digit]}
              onChange={(e) => {
                const newOtp = [...otp];
                newOtp[digit] = e.target.value;
                setOtp(newOtp);
                if (e.target.value.length === 1 && digit < 5)
                  focusInput(getRef(digit + 1));
                else if (e.target.value.length === 0 && digit > 0) {
                  const prevInputRef = getRef(digit - 1);
                  focusInput(prevInputRef);
                  setTimeout(() => {
                    prevInputRef.current.select();
                  }, 0);
                }
              }}
              onClick={() => {
                getRef(digit).current.select();
              }}
              onPaste={(e) => {
                e.preventDefault();
                const pastedData = e.clipboardData.getData('text');
                const newOtp = [...otp];
                pastedData.split('').forEach((char, index) => {
                  if (digit + index < 6) {
                    newOtp[digit + index] = char;
                  }
                });
                setOtp(newOtp);
                if (digit + pastedData.length < 6) {
                  focusInput(getRef(digit + pastedData.length));
                }
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <Button
          className="font-bold w-full mt-2"
          size="lg"
          rounded="full"
          theme="red"
          type="submit"
          disabled={loading}
        >
          Verify Code
        </Button>
      </div>
      <div
        className={`${
          msg.type === "error" ? "color-red" : "color-green"
        } text-sm text-end ${msg.type ? "visible" : "invisible h-[1.25rem]"}`}
      >
        {msg.msg}
      </div>
      <p className="color-black mt-4">
        Didn't receive the code?{" "}
        <span className="font-semibold cursor-pointer" onClick={handleResend}>
          Resend
        </span>
      </p>
    </form>
  );
};

export default OTP;
