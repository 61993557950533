import { useState, useEffect } from "react";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/dist/geosearch.css";

export const useSearchSuggestions = (query) => {
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const provider = new OpenStreetMapProvider({
    params: {
      countrycodes: "in",
      addressdetails: 1,
      // limit: 4,
    },
  });
  const getSearchSuggestions = async (query) => {
    try {
      const data = await provider.search({ query });
      // filter repeated results
      const filteredData = data.filter(
        (item, index, self) =>
          index === self.findIndex((i) => i.label === item.label)
      );
      setSearchSuggestions(
        filteredData.map((item) => {
          let address = "";
          if (item.raw.address.road) address += item.raw.address.road;
          if (item.raw.address.suburb) address += " " + item.raw.address.suburb;
          if (item.raw.address.neighbourhood)
            address += " " + item.raw.address.neighbourhood;
          if (item.raw.address.county) address += " " + item.raw.address.county;
          return {
            name: item.raw.name || item.raw.address.neighbourhood,
            label: item.label,
            address: address,
            city:
              item.raw.address.city ||
              item.raw.address.town ||
              item.raw.address.village,
            district: item.raw.address.state_district,
            state: item.raw.address.state,
            bounds: item.bounds,
            coordinates: {
              lat: item.raw.lat,
              lng: item.raw.lon,
            },
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
    setLoadingSuggestions(false);
  };
  // throttle search suggestions
  useEffect(() => {
    setLoadingSuggestions(true);
    let timeoutID = setTimeout(() => {
      if (query) {
        getSearchSuggestions(query);
      }
    }, 1000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [query]);

  return [searchSuggestions, loadingSuggestions];
};
