import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import PassContext from "../../../utils/PassContext";
import api from "../../../utils/api";
import Button from "../../../common/Button";
import { AiOutlineMail, AiOutlineCheckCircle } from "react-icons/ai";

const RequestPricingCard = ({ setShowModal, banquet }) => {
  return (
    <>
      <RequestPricingCardMain banquet={banquet} modal={true} />
      <div className="backdrop" onClick={() => setShowModal(false)} />
    </>
  );
};

export function RequestPricingCardMain({ banquet, modal = false }) {
  const [clientData, setClientData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    banquetName: banquet.name,
    banquetId: banquet._id,
    bookingDate: new Date().toISOString().slice(0, 10).replace(/-/g, "-"),
    guests: "",
  });
  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });

  const sendMessageToBanquetOwner = async () => {
    try {
      const { data } = await api.post("/user/message", {
        data: clientData,
      });
      console.log(data);
      setMsg({
        type: "success",
        text: data.message,
      });
      setClientData({
        name: "",
        email: "",
        phone: "",
        message: "",
        banquetName: banquet.name,
        banquetId: banquet._id,
        bookingDate: "",
        guests: "",
      });
    } catch (err) {
      console.log(err);
      setMsg({
        type: "error",
        text: err?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const { loggedUser } = useContext(PassContext);
  if (!loggedUser) {
    return (
      <div className={`auth-card pricing-card ${modal ? "modal" : ""}`}>
        <div className="flex gap-4 items-center justify-center mb-4">
          <AiOutlineMail className="color-red text-2xl" />
          <h3>Message Vendor</h3>
        </div>
        <p className="color-darkgrey">
          Login now to get access to all the features of the website
        </p>
        <ul className="flex flex-col gap-2 justify-center my-4 color-black">
          <li className="flex items-center gap-2">
            <AiOutlineCheckCircle className="color-darkgreen" />
            Message Vendors
          </li>
          <li className="flex items-center gap-2">
            <AiOutlineCheckCircle className="color-darkgreen" />
            Get location details
          </li>
          <li className="flex items-center gap-2">
            <AiOutlineCheckCircle className="color-darkgreen" />
            Save your favorites
          </li>
          <li className="flex items-center gap-2">
            <AiOutlineCheckCircle className="color-darkgreen" />
            Get exact details of listed banquets
          </li>
          <li className="flex items-center gap-2">
            <AiOutlineCheckCircle className="color-darkgreen" />
            Use our offers
          </li>
        </ul>
        <Link
          to={`/auth/login?redirect=${
            window.location.pathname + window.location.search
          }`}
        >
          <Button className="font-semibold w-full" size="md" theme="red">
            Login
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <form
      className={`auth-card pricing-card ${modal ? "modal" : ""}`}
      onSubmit={(e) => {
        e.preventDefault();
        sendMessageToBanquetOwner();
      }}
    >
      <div className="flex gap-4 items-center justify-center mb-4">
        <AiOutlineMail className="color-red text-2xl" />
        <h3>Message Vendor</h3>
      </div>
      <input
        className="w-full"
        placeholder="Name"
        value={clientData.name}
        onChange={(e) => {
          setClientData({
            ...clientData,
            name: e.target.value,
          });
        }}
        required
      />
      <input
        className="w-full"
        type="email"
        placeholder="Email"
        value={clientData.email}
        onChange={(e) => {
          setClientData({
            ...clientData,
            email: e.target.value,
          });
        }}
        required
      />
      <input
        className="w-full"
        placeholder="Phone"
        value={clientData.phone}
        onChange={(e) => {
          const inputPhoneNo = e.target.value;
          // Remove all non-digit characters from the input
          const formattedPhoneNo = inputPhoneNo.replace(/\D/g, "");
          setClientData((prev) => ({
            ...prev,
            phone: formattedPhoneNo,
          }));
        }}
        maxLength={10}
        minLength={10}
        required
      />
      <input
        className="w-full"
        placeholder="Date"
        type="date"
        value={clientData.bookingDate}
        onChange={(e) => {
          setClientData({
            ...clientData,
            bookingDate: e.target.value,
          });
        }}
        required
      />
      <input
        className="w-full"
        placeholder="Number of Guests"
        value={clientData.guests}
        onChange={(e) => {
          const val = e.target.value;
          // Remove all non-digit characters from the input
          const formattedVal = val.replace(/\D/g, "");
          setClientData({
            ...clientData,
            guests: formattedVal,
          });
        }}
        required
      />
      <textarea
        className="w-full"
        placeholder="Message"
        value={clientData.message}
        onChange={(e) => {
          setClientData({
            ...clientData,
            message: e.target.value,
          });
        }}
        required
      />
      <Button
        className="font-bold w-full mt-2"
        size="lg"
        theme="red"
        type="submit"
      >
        Request Pricing
      </Button>
      <div
        className={`${
          msg.type === "error" ? "color-red" : "color-green"
        } text-sm text-end ${msg.type ? "visible" : "invisible h-[1.25rem]"}`}
      >
        {msg.text}
      </div>
      <p className="mt-2 leading-5 color-darkgrey">
        By clicking "Send request" you agree to sign up and accept{" "}
        <Link to="/responsible-disclosure" className="underline">
          Terms of Use
        </Link>
      </p>
    </form>
  );
}

export default RequestPricingCard;
