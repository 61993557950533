import { useRouteError, Link } from "react-router-dom";
// import { Helmet } from "react-helmet";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Button from "../../common/Button";
const Error = () => {
  const err = useRouteError();
  return (
    <>
      {/* <Helmet>
        <title>Banquet Near Me | Error</title>
        <meta
          name="description"
          content="We're sorry, but something went wrong. We're working to get things back to normal as quickly as possible."
        />
      </Helmet> */}
      <Header />
      <div className="lg:p-32 md:p-16 p-8 flex flex-col justify-center items-center">
        <h1>Error</h1>
        {err.status && (
          <h3>
            {err.status}: {err.statusText}
          </h3>
        )}
        <p className="color-black my-2">({err.data})</p>
        <Link to="/" className="my-4">
          <Button theme="red" size="lg">
            Go to Home
          </Button>
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Error;
