import { useState, useEffect } from "react";
import api from "../../../utils/api";
import { Rating } from "react-simple-star-rating";
import Button from "../../../common/Button";
import myToast from "../../../utils/myToast";

function StarRater({ rating, setRating }) {
  return (
    <Rating
      onClick={(rate) => setRating(rate)}
      initialValue={rating}
      allowFraction
      emptyStyle={{ display: "flex" }}
      SVGstyle={{ display: "inline-block", marginBottom: 10 }}
      size={18}
    />
  );
}

const AddReview = ({ banquetId, getListing, loading }) => {
  const [user, setUser] = useState({});
  const getUser = async () => {
    try {
      const { data } = await api.get("/user/profile");
      console.log(data);
      setUser(data.user);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  const [userRating, setUserRating] = useState({
    Qos: 0,
    professionalism: 0,
    flexibility: 0,
    value: 0,
    responseTime: 0,
  });
  const [userReview, setUserReview] = useState({
    heading: "",
    description: "",
  });
  const addRating = async () => {
    let parsedIntData = {};
    for (const key in userRating) {
      parsedIntData[key] = parseInt(userRating[key]);
    }
    try {
      const { data } = await api.post("/user/addRating/" + banquetId, {
        data: parsedIntData,
      });
      console.log(data);
      addReview();
    } catch (err) {
      console.log(err);
    }
  };
  const addReview = async () => {
    try {
      const avgRating =
        (+userRating.Qos +
          +userRating.professionalism +
          +userRating.flexibility +
          +userRating.value +
          +userRating.responseTime) /
        5;
      const { data } = await api.post("/user/addReview/" + banquetId, {
        data: { ...userReview, rating: avgRating },
      });
      console.log(data);
      setUserRating({
        Qos: 0,
        professionalism: 0,
        flexibility: 0,
        value: 0,
        responseTime: 0,
      });
      setUserReview({
        heading: "",
        description: "",
      });
      getListing();
    } catch (err) {
      console.log(err);
      myToast(
        err?.response?.data?.message || "Something went wrong",
        "failure"
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addRating();
  };

  if (loading) return null;

  return (
    <div className="my-8 add-review">
      <form onSubmit={handleSubmit}>
        <h4 className="mb-4">Adding review as {user.name}</h4>

        <div className="flex gap-x-4 flex-wrap user-ratings-subform">
          <div className="flex items-start">
            <label htmlFor="Qos">Quality of Service</label>
            <StarRater
              rating={userRating.Qos}
              setRating={(rate) => setUserRating({ ...userRating, Qos: rate })}
            />
          </div>
          <div className="flex items-start">
            <label htmlFor="professionalism">Professionalism</label>
            <StarRater
              rating={userRating.professionalism}
              setRating={(rate) =>
                setUserRating({ ...userRating, professionalism: rate })
              }
            />
          </div>
          <div className="flex items-start">
            <label htmlFor="flexibility">Flexibility</label>
            <StarRater
              rating={userRating.flexibility}
              setRating={(rate) =>
                setUserRating({ ...userRating, flexibility: rate })
              }
            />
          </div>
          <div className="flex items-start">
            <label htmlFor="value">Value</label>
            <StarRater
              rating={userRating.value}
              setRating={(rate) =>
                setUserRating({ ...userRating, value: rate })
              }
            />
          </div>
          <div className="flex items-start">
            <label htmlFor="responseTime">Response Time</label>
            <StarRater
              rating={userRating.responseTime}
              setRating={(rate) =>
                setUserRating({ ...userRating, responseTime: rate })
              }
            />
          </div>
        </div>
        <div>
          <input
            type="text"
            className="w-full mt-4 mb-2"
            placeholder="Heading"
            value={userReview.heading}
            onChange={(e) =>
              setUserReview({ ...userReview, heading: e.target.value })
            }
            required
          />
          <textarea
            type="text"
            className="w-full mb-2"
            placeholder="Description"
            rows={4}
            value={userReview.description}
            onChange={(e) =>
              setUserReview({ ...userReview, description: e.target.value })
            }
            required
          />
        </div>
        <div className="flex justify-end">
          <Button theme="red" type="submit" size="lg" className="font-medium">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddReview;
