import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import axios from "axios";
import { Helmet } from "react-helmet";
import { useWindowWidth } from "../../utils/useWindowWidth";
import api from "../../utils/api";
import ListingHead from "./components/ListingHead";
import ListingMenu from "./components/ListingMenu";
import CarousalComponent from "./components/Carousal";
import RequestPricingCard, {
  RequestPricingCardMain,
} from "../Listings/components/RequestPricingCard";
import ListingInfo from "./components/ListingInfo";
import ListingMoreInfo from "./components/ListingMoreInfo";
import ListingMapContainer from "./components/ListingMapContainer";
import ListingReviews from "./components/ListingReviews";
import CarousalDialog from "./components/CarousalDialog";
import "./ListingDetails.css";

const ListingPage = () => {
  const { id } = useParams();

  const [listing, setListing] = useState({});
  const [loading, setLoading] = useState(false);
  const getListing = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/user/banquet/" + id);
      console.log(data);
      setListing(data.banquet);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  // const getMyIp = async () => {
  //   const myIp = sessionStorage.getItem("my-ip");
  //   if (myIp) return myIp;
  //   try {
  //     const { data } = await axios.get("https://api.ipify.org/?format=json");
  //     sessionStorage.setItem("my-ip", data.ip);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  useEffect(() => {
    getListing();
  }, []);

  const [activeTab, setActiveTab] = useState("photos");
  const [showModal, setShowModal] = useState(false);

  const [showBigCarousal, setShowBigCarousal] = useState(false);
  const windowWidth = useWindowWidth();

  return (
    <>
      <Helmet>
        <title>{`${listing?.name || "Result"} | Banquet Near Me`}</title>
        <meta
          name="description"
          content="Explore this banquet and find out more about its features and details. Make an informed decision with our comprehensive information."
        />
      </Helmet>
      <div className="listing-details-page page-layout">
        <ListingHead
          id={id}
          {...listing}
          loading={loading}
          setShowModal={setShowModal}
        />
        <ListingMenu activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="carousel-msg-info-container">
          <div>
            <div
              onClick={() => {
                if (windowWidth > 896) setShowBigCarousal(true);
              }}
              id="photos"
            >
              <CarousalComponent
                images={listing.images}
                loading={loading}
                setShowBigCarousal={setShowBigCarousal}
              />
            </div>
            <ListingInfo info={listing.info} loading={loading} />
          </div>
          {!loading && (
            <div className="hide-on-md-devices">
              <RequestPricingCardMain banquet={listing} />
              {/* <div className="flex justify-center">
            <Button
              theme="blue"
              className="flex justify-center w-full mt-4"
              rounded="sm"
            >
              Book Now
            </Button>
          </div> */}
            </div>
          )}
        </div>
        <ListingMoreInfo
          moreInfo={listing.moreInfo}
          facilities={listing.facilities}
          pricePerPlate={listing.pricePerPlate}
          capacity={listing.capacity}
          occasions={listing.occasions}
          loading={loading}
        />
        <div id="map">
          <h3 className="font-bold">Location</h3>
          <hr
            className="my-2"
            style={{
              backgroundColor: "var(--grey2)",
              height: "0.125rem",
            }}
          />
          <ListingMapContainer listing={listing} loading={loading} />
        </div>
        {!loading && (
          <div className="show-on-md-devices flex flex-col items-center py-8">
            <RequestPricingCardMain banquet={listing} />
            {/* <div className="flex justify-center w-full">
          <Button
            theme="blue"
            className="flex justify-center w-full mt-2"
            rounded="sm"
          >
            Book Now
          </Button>
        </div> */}
          </div>
        )}
        <ListingReviews
          name={listing.name}
          reviews={listing.reviews}
          rating={listing.rating}
          banquetId={id}
          getListing={getListing}
          loading={loading}
        />
      </div>
      {showModal && (
        <RequestPricingCard
          setShowModal={setShowModal}
          banquet={listing}
          modal={true}
        />
      )}
      {showBigCarousal && !loading && (
        <>
          <div className="modal carousal-dialog">
            <CarousalDialog
              images={listing.images}
              setShowBigCarousal={setShowBigCarousal}
            />
          </div>
          <div className="backdrop" onClick={() => setShowBigCarousal(false)} />
        </>
      )}
    </>
  );
};

export default ListingPage;
