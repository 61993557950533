import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";
import dateFormatter from "../../utils/dateFormatter";
import Button from "../../common/Button";
import PlanCard from "./components/PlanCard";

const AllPlans = () => {
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const getOwnerPlan = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/banquet/getOwner");
      console.log(data);
      let plans = data.owner.plans;
      plans.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setPlans(data.owner.plans);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOwnerPlan();
  }, []);

  const isExpiryWithinOneMonth = (date) => {
    const expiryDate = new Date(date);
    const today = new Date();
    const oneMonth = new Date(today.setMonth(today.getMonth() + 1));
    return expiryDate < oneMonth;
  };

  return (
    <div className="page-layout">
      <div className="mb-8 flex justify-between items-center">
        <div>
          <h2>Your Plans</h2>
          <p>
            You can buy a plan to get more features and increase your business
          </p>
        </div>
        <Button
          theme="red"
          size="lg"
          className="font-medium"
          handleClick={() =>
            navigate(
              `/banquet-home/member-pricing?currentPlan=${plans[0]?.name}`
            )
          }
        >
          View Our Plans
        </Button>
      </div>
      <div className="max-w-[50rem]">
        {loading ? (
          [1, 2, 3].map((item) => (
            <div key={item} className="plan-card skeleton-loader mb-8"></div>
          ))
        ) : plans.length > 0 ? (
          plans.map((plan) => (
            <PlanCard plan={plan} key={plan._id} className="mb-8" />
          ))
        ) : (
          <PlanCard
            plan={{
              name: "Basic",
              status: "active",
              price: "",
              createdAt: "",
              expiresAt: "2024-12-31T23:59:59.118Z",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AllPlans;
