import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearchSuggestions } from "../../../utils/useSearchSuggestions";
import Button from "../../../common/Button";
import { MdSearch } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { LiaCitySolid } from "react-icons/lia";

const BannerSearch = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [locality, setLocality] = useState(searchParams.get("locality") || "");
  const [date, setDate] = useState(
    searchParams.get("date") ||
      new Date().toISOString().slice(0, 10).replace(/-/g, "-")
  );
  const [people, setPeople] = useState(50);

  const [searchSuggestions, loadingSuggestions] = useSearchSuggestions(locality);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const getCityFromSuggestion = (suggestion) => {
    if (!suggestion) return "";
    if (suggestion.city) return suggestion.city;
    if (suggestion.district) return suggestion.district;
    if (suggestion.state) return suggestion.state;
    return "";
  };
  const getUpdatedLocalityAndCity = () => {
    let search = locality.toLowerCase();
    if (
      search === "delhi" ||
      search === "new delhi" ||
      search === "noida" ||
      search === "gurgaon" ||
      search === "gurugram" ||
      search === "gurugram district" ||
      search === "ghaziabad" ||
      search === "faridabad"
    )
      return ["", search];
    else return [locality, getCityFromSuggestion(selectedSuggestion)];
  };
  function handleSubmit(e) {
    e.preventDefault();
    const [queryLocality, queryCity] = getUpdatedLocalityAndCity();
    navigate(
      `/listings?locality=${queryLocality}&city=${queryCity}&date=${date}&people=${people}`
    );
  }

  const [isLocalityInputFocused, setLocalityInputFocused] = useState(false);

  return (
    <form className="home-banner-search-bar" onSubmit={handleSubmit}>
      <div>
        <div className="flex items-center justify-center">
          <input
            type="text"
            placeholder="Search for a location"
            className="location"
            value={locality}
            onChange={(e) => {
              setLocality(e.target.value);
            }}
            onFocus={() => setLocalityInputFocused(true)}
            onBlur={() => {
              setTimeout(() => setLocalityInputFocused(false), 500);
            }}
            required
          />
          <LiaCitySolid className="relative right-6" />
        </div>
        {locality.length > 0 && isLocalityInputFocused && (
          <div className="search-suggestions">
            {loadingSuggestions ? (
              <div className="p-2 hover:bg-gray-100">
                <h3 className="text-sm font-medium">Loading...</h3>
                <p className="text-xs text-gray-500">
                  Please wait while we fetch the results
                </p>
              </div>
            ) : searchSuggestions.length > 0 ? (
              searchSuggestions.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col items-start justify-center gap-1 p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setLocality(item.name);
                    setSelectedSuggestion(item);
                  }}
                >
                  <h3 className="text-sm font-medium">{item.name}</h3>
                  <p className="text-xs text-gray-500">{item.label}</p>
                </div>
              ))
            ) : (
              <div className="p-2 hover:bg-gray-100">
                <h3 className="text-sm font-medium">No results found</h3>
                <p className="text-xs text-gray-500">
                  Please try a different search
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center justify-center gap-2">
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
          className="date"
        />
        <FiUsers className="hidden" />
      </div>
      <div className="flex items-center justify-center">
        <input
          type="text"
          value={people}
          onChange={(e) => {
            if (!isNaN(e.target.value)) setPeople(e.target.value);
          }}
          required
          className="guests"
        />
        <FiUsers className="relative right-6" />
      </div>
      <Button
        className="flex items-center gap-1"
        theme="red"
        rounded="sm"
        type="submit"
      >
        <MdSearch className="h-[1.4rem] w-[1.4rem]" />
        Search
      </Button>
    </form>
  );
};

export default BannerSearch;
