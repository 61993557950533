import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { TbShare3 } from "react-icons/tb";
import { GiPartyPopper } from "react-icons/gi";
import Separator from "../../../common/Separator";

const Steps = () => {
  const steps = [
    {
      icon: <AiOutlineSearch className="color-red h-[3rem] w-[3rem]" />,
      imgId: "Banquet-Step1.png",
      theme: "red",
      title: "Discover Your Dream Banquet",
      description:
        "Browse our comprehensive list of banquets to find the one that perfectly matches your vision.",
    },
    {
      icon: <TbShare3 className="color-yellow h-[3rem] w-[3rem]" />,
      imgId: "Banquet-Step2.png",
      theme: "yellow",
      title: "Connect with Your Chosen Banquet",
      description:
        "Once you've found your ideal banquet, get in touch with them to start orchestrating your event.",
    },
    {
      icon: <GiPartyPopper className="color-blue h-[3rem] w-[3rem]" />,
      imgId: "Banquet-Step3.png",
      theme: "blue",
      title: "Create Unforgettable Memories",
      description:
        "Celebrate your special occasion and make lasting memories with your loved ones.",
    },
  ];

  return (
    <div>
      <h1 className="text-center my-16">Simple Steps</h1>
      {steps.map((step, index) => (
        <div key={index} className="step">
          <div
            className={`flex items-center gap-4 relative z-10 ${
              index % 2 ? "flex-row-reverse" : ""
            }`}
          >
            <img src={`/assets/${step.imgId}`} alt="step1-visuals" />
            <div className="p-4">
              <div className="heading">
                <div>{step.icon}</div>
                <h2 className="my-4">{step.title}</h2>
              </div>
              <p className="color-darkgrey font-medium text-lg">
                {step.description}
              </p>
            </div>
          </div>
          <Separator
            theme={step.theme}
            className="relative top-[-5.5rem] mx-72"
          />
        </div>
      ))}
    </div>
  );
};

export default Steps;
