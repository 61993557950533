import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import api from "../../../utils/api";
import Button from "../../../common/Button";
import { IoCallOutline } from "react-icons/io5";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMail,
} from "react-icons/ai";
import Terms from "./Terms";

const Register = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const inputPassword = useRef(null);

  useEffect(() => {
    if (inputPassword) {
      if (showPassword) {
        inputPassword.current.type = "text";
      } else {
        inputPassword.current.type = "password";
      }
    }
  }, [showPassword, inputPassword]);

  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    terms: false,
    error: "",
  });

  const [loading, setLoading] = useState(false);
  const createUser = async () => {
    setLoading(true);
    try {
      const { data } = await api.post("/user/signup", {
        data: {
          name: registerData.name,
          password: registerData.password,
          email: registerData.email,
          phone: registerData.phone,
        },
      });
      console.log(data);
      navigate("/auth/otp", {
        state: { id: data.result, email: registerData.email },
      });
    } catch (e) {
      console.log(e);
      setRegisterData((prev) => ({
        ...prev,
        error: e?.response?.data?.message || "Something went wrong",
      }));
    }
    setLoading(false);
  };

  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createUser();
        }}
      >
        <Helmet>
          <title>Banquet Near Me | Register</title>
          <meta
            name="description"
            content="Create your account on Banquet Near Me App. Enjoy personalized recommendations and book your favorite banquet halls with ease."
          />
        </Helmet>
        <h3>Register Account</h3>
        <div className="mb-4">
          <h5 className="font-semibold mb-2">Your Name</h5>
          <div>
            <input
              type="text"
              className="w-full"
              placeholder="Full Name"
              value={registerData.name}
              onChange={(e) => {
                setRegisterData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <h5 className="font-semibold mb-2">Email Address</h5>
          <div className="relative">
            <AiOutlineMail size={24} className="input-icon" />
            <input
              type="email"
              className="w-full with-icon"
              placeholder="example@mail.com"
              value={registerData.email}
              onChange={(e) => {
                setRegisterData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
              }}
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <h5 className="font-semibold mb-2">Contact Number</h5>
          <div className="relative">
            <IoCallOutline size={24} className="input-icon" />
            <input
              type="text"
              className="w-full with-icon"
              value={registerData.phone}
              onChange={(e) => {
                const inputPhoneNo = e.target.value;
                // Remove all non-digit characters from the input
                const formattedPhoneNo = inputPhoneNo.replace(/\D/g, "");
                setRegisterData((prev) => ({
                  ...prev,
                  phone: formattedPhoneNo,
                }));
              }}
              maxLength={10}
              minLength={10}
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <h5 className="font-semibold mb-2">Password</h5>
          <div className="relative">
            {showPassword ? (
              <AiOutlineEyeInvisible
                className="cursor-pointer input-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPassword(false);
                }}
                size={24}
              />
            ) : (
              <AiOutlineEye
                className="cursor-pointer input-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPassword(true);
                }}
                size={24}
              />
            )}
            <input
              type="password"
              className="w-full with-icon"
              ref={inputPassword}
              value={registerData.password}
              onChange={(e) => {
                setRegisterData((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
              }}
              required
            />
          </div>
        </div>
        <div className="flex gap-2 mb-4">
          <input
            type="checkbox"
            value={registerData.terms}
            onChange={(e) => {
              setRegisterData((prev) => ({
                ...prev,
                terms: e.target.checked,
              }));
            }}
            required
          />
          <span className="color-darkgrey">
            Agree with{" "}
            <span
              onClick={() => setShowTerms(true)}
              className="font-semibold cursor-pointer"
            >
              Terms & Conditions
            </span>
          </span>
        </div>
        <Button
          className="font-bold w-full mt-2"
          size="lg"
          rounded="full"
          theme="red"
          type="submit"
          disabled={loading}
        >
          Create Account
        </Button>
        <div
          className={`color-red text-sm text-end ${
            registerData.error ? "visible" : "invisible h-[1.25rem]"
          }`}
        >
          {registerData.error}
        </div>
        <div className="color-black mt-4 text-center flex flex-wrap gap-x-2">
          Already have an account?
          <Link to="/auth/login" className="font-semibold cursor-pointer">
            Login to Account
          </Link>
        </div>
      </form>
      {showTerms && <Terms setShowTerms={setShowTerms} />}
    </>
  );
};

export default Register;
