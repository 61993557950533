export default function distanceBetweenCoordinates(lat1, lon1, lat2, lon2) {
  if (!lat1 && !lon1 && !lat2 && !lon2) return null;
  // if not numbers then return null
  if (isNaN(lat1) && isNaN(lon1) && isNaN(lat2) && isNaN(lon2)) return null;

  if (lat1 === lat2 && lon1 === lon2) return 0;
  else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) dist = 1;
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    // now we have dist in km
    dist = dist.toFixed();
    return dist;
  }
}
