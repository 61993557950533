import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "react-simple-toasts/dist/theme/success.css";
import "react-simple-toasts/dist/theme/failure.css";
import Layout from "./layout";
import { LayoutWithoutNavbar } from "./layout";
import { useEffect, useState, lazy, Suspense } from "react";
import jwtDecode from "jwt-decode";
import PassContext from "./utils/PassContext";
import ScrollToTop from "./utils/ScrollToTop";
import Error from "./pages/Error";
import "./App.css";

import Home from "./pages/Home";
import LoginFlowLayout from "./pages/Auth";
import Login from "./pages/Auth/components/Login";
import Register from "./pages/Auth/components/Register";
import OTP from "./pages/Auth/components/OTP";
import SocialRedirection from "./pages/Auth/components/SocialRedirection.js";
import Membership from "./pages/Membership";
import Listings from "./pages/Listings";
import ListingDetails from "./pages/ListingDetails";

import BanquetPricing from "./pages/BanquetHome/PricingPage";

import FullPageLoader from "./common/FullPageLoader";
import AllPlans from "./pages/BanquetHome/AllPlans.js";

const AdminPanel = lazy(() => import("./pages/AdminPanel"));
const AddBlog = lazy(() => import("./pages/AdminPanel/AddBlog"));
const EditBlog = lazy(() => import("./pages/AdminPanel/EditBlog"));
const ManageSubscriptions = lazy(() =>
  import("./pages/AdminPanel/ManageSubscriptions")
);
const ManageBanquetOwner = lazy(() =>
  import("./pages/AdminPanel/ManageBanquetOwner")
);
const ManageBanquets = lazy(() => import("./pages/AdminPanel/ManageBanquets"));
const BanquetHome = lazy(() => import("./pages/BanquetHome"));
const AddBanquet = lazy(() => import("./pages/AddBanquet"));
const UpdateBanquet = lazy(() => import("./pages/UpdateBanquet"));

const ContactUs = lazy(() => import("./pages/ContactUs"));
const PasswordReset = lazy(() =>
  import("./pages/Auth/components/PasswordReset")
);
const IncomingPasswordReset = lazy(() =>
  import("./pages/IncomingPasswordReset")
);
const ResponsibleDisclosure = lazy(() =>
  import("./pages/ResponsibleDisclosure")
);
const TermsConditions = lazy(() =>
  import("./pages/ResponsibleDisclosure/Terms")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/ResponsibleDisclosure/Privacy")
);
const RefundPolicy = lazy(() => import("./pages/ResponsibleDisclosure/Refund"));
const About = lazy(() => import("./pages/About"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const SavedBanquets = lazy(() => import("./pages/SavedBanquets"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Blog = lazy(() => import("./pages/Blogs/Blog"));
const FooterPages = lazy(() => import("./pages/FooterPages"));

const AppRouter = createBrowserRouter([
  {
    element: <ScrollToTop />,
    path: "/",
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/listings",
            element: <Listings />,
          },
          {
            path: "/listings/:id",
            element: <ListingDetails />,
          },
          {
            path: "/blogs",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <Blogs />
              </Suspense>
            ),
          },
          {
            path: "/blogs/:id",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <Blog />
              </Suspense>
            ),
          },
          {
            path: "/responsible-disclosure",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <ResponsibleDisclosure />
              </Suspense>
            ),
          },
          {
            path: "/refund-policy",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <RefundPolicy />
              </Suspense>
            ),
          },
          {
            path: "/terms-conditions",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <TermsConditions />
              </Suspense>
            ),
          },
          {
            path: "/privacy-policy",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <PrivacyPolicy />
              </Suspense>
            ),
          },
          {
            path: "/about-us",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <About />
              </Suspense>
            ),
          },
          {
            path: "/edit-profile",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <EditProfile />
              </Suspense>
            ),
          },
          {
            path: "/saved-banquets",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <SavedBanquets />
              </Suspense>
            ),
          },
          {
            path: "/auth",
            element: <LoginFlowLayout />,
            children: [
              {
                path: "/auth/login",
                element: <Login />,
              },
              {
                path: "/auth/banquet-login",
                element: <Login />,
              },
              {
                path: "/auth/admin-login",
                element: <Login />,
              },
              {
                path: "/auth/reset-password",
                element: (
                  <Suspense fallback={<FullPageLoader />}>
                    <PasswordReset />
                  </Suspense>
                ),
              },
              {
                path: "/auth/banquet-reset",
                element: (
                  <Suspense fallback={<FullPageLoader />}>
                    <PasswordReset />
                  </Suspense>
                ),
              },
              {
                path: "/auth/register",
                element: <Register />,
              },
              {
                path: "/auth/otp",
                element: <OTP />,
              },
              {
                path: "/auth/social-redirection",
                element: <SocialRedirection />,
              },
            ],
          },
          {
            path: "/membership",
            element: <Membership />,
          },
          {
            path: "/contact-us",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <ContactUs />
              </Suspense>
            ),
          },
          {
            path: "/about-us",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <FooterPages />
              </Suspense>
            ),
          },
          {
            path: "/blogs",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <FooterPages />
              </Suspense>
            ),
          },
          {
            path: "/privacy-policy",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <FooterPages />
              </Suspense>
            ),
          },
          {
            path: "/member-pricing",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <BanquetPricing />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/",
        element: <LayoutWithoutNavbar />,
        children: [
          {
            path: "/banquet-home",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <BanquetHome />
              </Suspense>
            ),
          },
          {
            path: "/banquet-home/add-new",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <AddBanquet />
              </Suspense>
            ),
          },
          {
            path: "/banquet-home/update-banquet/:id",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <UpdateBanquet />
              </Suspense>
            ),
          },
          {
            path: "/banquet-home/all-plans",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <AllPlans />
              </Suspense>
            ),
          },
          {
            path: "/banquet-home/member-pricing",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <BanquetPricing />
              </Suspense>
            ),
          },
          {
            path: "/admin-home",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <AdminPanel />
              </Suspense>
            ),
          },
          {
            path: "/admin-home/addblog",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <AddBlog />
              </Suspense>
            ),
          },
          {
            path: "/admin-home/editblog/:id",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <EditBlog />
              </Suspense>
            ),
          },
          {
            path: "/admin-home/manage-subscriptions",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <ManageSubscriptions />
              </Suspense>
            ),
          },
          {
            path: "/admin-home/manage-banquet-owner/:id",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <ManageBanquetOwner />
              </Suspense>
            ),
          },
          {
            path: "/admin-home/manage-banquets",
            element: (
              <Suspense fallback={<FullPageLoader />}>
                <ManageBanquets />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/incoming-password-reset",
        element: (
          <Suspense fallback={<FullPageLoader />}>
            <IncomingPasswordReset />
          </Suspense>
        ),
      },
    ],
    errorElement: <Error />,
  },
]);

function App() {
  const [loggedUser, setLoggedUser] = useState(null);
  const handleReturningUser = () => {
    if (localStorage.getItem("token")) {
      const decodedToken = jwtDecode(localStorage.getItem("token"));
      setLoggedUser({
        id: decodedToken.id,
        role: decodedToken.role,
      });
    }
  };
  useEffect(() => {
    handleReturningUser();
  }, []);

  return (
    <PassContext.Provider value={{ loggedUser, setLoggedUser }}>
      <RouterProvider router={AppRouter} />
    </PassContext.Provider>
  );
}

export default App;
