import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../utils/api";
import Button from "../../../common/Button";
import Terms from "../../Auth/components/Terms";

const MembershipCard = () => {
  const [memberDetails, setMemberDetails] = useState({
    name: "",
    email: "",
    phone: "",
    banquetName: "",
    terms: false,
  });
  const [msg, setMsg] = useState({
    type: "",
    text: "",
  });

  const handleSubmit = async (e) => {
    try {
      const { data } = await api.post("/banquet/becomePartner", {
        data: memberDetails,
      });
      console.log(data);
      // We'll send credentials to our member via his inputted email
      setMsg({
        type: "success",
        text: "We will contact you soon.",
      });
      setMemberDetails({
        name: "",
        email: "",
        phone: "",
        banquetName: "",
      });
    } catch (e) {
      console.log(e);
      setMsg({
        type: "error",
        text: e?.response?.data?.message || "Something went wrong",
      });
    }
  };
  const [showTerms, setShowTerms] = useState(false);
  return (
    <>
      <form
        className="auth-card membership"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <h3>Become Banquet Partner</h3>
        <div className="mb-6">
          <h5 className="font-semibold mb-2">Your Name</h5>
          <input
            type="text"
            className="w-full"
            placeholder="Enter Full Name"
            value={memberDetails.name}
            onChange={(e) =>
              setMemberDetails({ ...memberDetails, name: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-6">
          <h5 className="font-semibold mb-2">Banquet Name</h5>
          <input
            type="text"
            className="w-full"
            placeholder="Enter Banquet Name"
            value={memberDetails.banquetName}
            onChange={(e) =>
              setMemberDetails({
                ...memberDetails,
                banquetName: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="mb-6">
          <h5 className="font-semibold mb-2">Email Address</h5>
          <input
            type="email"
            className="w-full"
            placeholder="Enter Email Address"
            value={memberDetails.email}
            onChange={(e) =>
              setMemberDetails({ ...memberDetails, email: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-6">
          <h5 className="font-semibold mb-2">Phone Number</h5>
          <input
            type="text"
            className="w-full"
            placeholder="Enter Phone Number"
            value={memberDetails.phone}
            onChange={(e) => {
              const inputPhone = e.target.value;
              // Remove all non-digit characters from the input
              const formattedPhone = inputPhone.replace(/\D/g, "");
              setMemberDetails({ ...memberDetails, phone: formattedPhone });
            }}
            minLength={10}
            maxLength={10}
            required
          />
        </div>
        <div className="flex gap-2 mb-4">
          <input
            type="checkbox"
            value={memberDetails.terms}
            onChange={(e) => {
              setMemberDetails((prev) => ({
                ...prev,
                terms: e.target.checked,
              }));
            }}
            required
          />
          <span className="color-darkgrey">
            Agree with{" "}
            <span
              onClick={() => setShowTerms(true)}
              className="font-semibold cursor-pointer"
            >
              Terms & Conditions
            </span>
          </span>
        </div>
        <div>
          <Button
            className="font-bold w-full mt-2 text-lg"
            size="lg"
            rounded="full"
            theme="red"
            type="submit"
          >
            Request Call Back
          </Button>
        </div>
        <div
          className={`${
            msg.type === "error" ? "color-red" : "color-green"
          } text-sm text-end ${msg.type ? "visible" : "invisible h-[1.25rem]"}`}
        >
          {msg.text}
        </div>
        <div className="color-black mt-2 text-center flex flex-wrap gap-x-2">
          Already have an account?
          <Link
            to="/auth/banquet-login"
            className="font-semibold cursor-pointer"
          >
            Login to Account
          </Link>
        </div>
      </form>
      {showTerms && <Terms setShowTerms={setShowTerms} />}
    </>
  );
};

export default MembershipCard;
