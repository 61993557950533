import React from "react";
import { Link } from "react-router-dom";
import FooterLists from "./FooterLists";
import FooterNewsletter from "./FooterNewsletter";
import Socials from "./Socials";

const Footer = () => {
  return (
    <>
      <footer className="bg-beige">
        <div className="footer-body flex lg:gap-8 gap-4">
          <div className="info">
            <Link className="logo-container" to="/">
              <img src="/assets/logo.svg" alt="logo" className="logo" />
            </Link>
            <Socials />
            <p className="my-8 color-darkgrey">
              Banquet Near Me is committed towards building an organization
              centered on the highest standards of transparency and corporate
            </p>
            {/* <div className="flex gap-4 appstore-btns"> */}
            {/* <img src="/assets/PlaystoreBtn.svg" alt="playstore" /> */}
            {/* <img src="/assets/AppleStoreBtn.svg" alt="appstore" /> */}
            {/* </div> */}
          </div>
          <FooterLists />
          <FooterNewsletter />
        </div>
        <FooterLists className="expanded" />
        <FooterNewsletter className="expanded" />
      </footer>
      <div className="bg-yellow text-center py-2 font-medium color-black text-sm px-4">
        Copyright © 2024 Banquet Near Me. All rights reserved. Designed by
        Digital Night Owl.
      </div>
    </>
  );
};

export default Footer;
