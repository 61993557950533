import React from "react";
import { useNavigate } from "react-router-dom";
import generateImageUrl from "../utils/generateImageUrl";
import { AiFillHeart } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import "./BanquetCard.css";

const BanquetCard = (props) => {
  const navigate = useNavigate();

  function padStart(number) {
    if (number.toString().length < 2) return ("0" + number).slice(-2);
    return number;
  }
  const { _id, name, type, special, rating, reviews, images } = props;

  return (
    <div
      className="banquet-card cursor-pointer"
      onClick={() => {
        navigate(`/listings/${_id}`);
      }}
    >
      <img src={generateImageUrl(images[0])} alt="banquet" />
      <div className="flex">
        <div className="flex flex-col h-44 p-4 justify-center gap-4 bg-lightgrey rounded-bl-xl color-black">
          <span className="flex flex-col items-center text-sm">
            <AiFillHeart className="like-icon" />
            {padStart(rating?.ratersCount || 0)}
          </span>
          <span className="flex flex-col items-center text-sm">
            <FaComment className="comment-icon" />
            {padStart(reviews.length || 0)}
          </span>
        </div>
        <div className="details-parent">
          <div className="details p-4">
            <div className="my-4">
              <h4 className="font-bold">{name}</h4>
              <div className={`text-sm font-semibold color-${type}`}>
                {type}
              </div>
            </div>
            <p className="my-4 limit-to-3-lines">{special}</p>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PlaceholderCard = () => {
  return (
    <div className="banquet-card placeholder">
      <div className="placeholder-img skeleton-loader"></div>
      <div className="flex">
        <div className="flex flex-col h-44 p-4 justify-center gap-4 bg-lightgrey rounded-bl-lg color-black">
          <span className="flex flex-col items-center text-sm">
            <AiFillHeart className="like-icon" />
            <span className="skeleton-loader count my-1"></span>
          </span>
          <span className="flex flex-col items-center text-sm">
            <FaComment className="comment-icon" />
            <span className="skeleton-loader count my-1"></span>
          </span>
        </div>
        <div className="details-parent">
          <div className="details p-4">
            <div className="my-4">
              <div className="h4 skeleton-loader"></div>
              <div className="type skeleton-loader mt-1"></div>
            </div>
            <p className="skeleton-loader my-2"></p>
            <p className="skeleton-loader my-2"></p>
            <p className="skeleton-loader my-2"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BanquetCard;
