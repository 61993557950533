import React from "react";
import dateFormatter from "../../../utils/dateFormatter";

const PlanCard = ({ plan, className = "" }) => {
  const isExpiryWithinOneMonth = (date) => {
    const expiryDate = new Date(date);
    const today = new Date();
    const oneMonth = new Date(today.setMonth(today.getMonth() + 1));
    return expiryDate < oneMonth;
  };
  
  return (
    <div className={`plan-card ${plan.status} ${className}`}>
      {plan.status === "Active" &&
        (isExpiryWithinOneMonth(plan.expiresAt) ? (
          <div className="expiring-soon">EXPIRING SOON</div>
        ) : (
          <div className="expiring-soon blue">ACTIVE</div>
        ))}
      <div className="flex justify-between items-start">
        <div>
          <h3 className="uppercase font-bold">{plan.name}</h3>
          {plan.name === "Basic" ? (
            <p>
              Free{" "}
              <span className="color-red font-medium">
                (View our plans to boost your business today!)
              </span>
            </p>
          ) : (
            <p>
              Bought for ₹{plan.price} on {dateFormatter(plan.createdAt)}
            </p>
          )}
        </div>
        <div>
          <h3 className="font-bold capitalize">{plan.status}</h3>
          <p>Expires on - {dateFormatter(plan.expiresAt)}</p>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
