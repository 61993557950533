import React from "react";
import Button from "../common/Button";

const FooterNewsLetter = ({ className = "" }) => {
  return (
    <div className={`newsletter ${className}`}>
      <h4 className="uppercase mb-2">Newsletter</h4>
      <p className="color-black">Subscribe To Our Newsletter</p>
      <form>
        <input placeholder="Name" required className="w-full my-2" />
        <input
          placeholder="Email"
          type="email"
          required
          className="w-full my-2"
        />
        <Button type="submit" theme="red" rounded="xs" className="w-full my-2 font-semibold">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default FooterNewsLetter;
