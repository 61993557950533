import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../../utils/api";
import myToast from "../../../utils/myToast";
import Button from "../../../common/Button";
import "../BanquetHome.css";

const MemberPricingCard = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const activePlanName = searchParams.get("currentPlan");
  const { title, desc, currPrice, oldPrice, features, index } = props;
  const theme = index % 2 === 0 ? "blue" : "red";

  const getSave = (old, newVal) => {
    old = old.split(",").join("");
    newVal = newVal.split(",").join("");
    let save = ((old - newVal) / old) * 100 || 0;
    return Math.round(save);
  };

  const [loading, setLoading] = useState(false);
  const sendMailToAdmin = async (plan) => {
    setLoading(true);
    try {
      const { data } = await api.post("/banquet/buyPlan", {
        plan,
      });
      myToast(data.message, "success");
    } catch (err) {
      console.log(err);
      myToast(err.response?.data?.message || "Something went wrong", "failure");
    }
    setLoading(false);
  };

  const BtnData = () => {
    if (index === 0) return { text: "DEFAULT PLAN", disabled: true };
    if(!activePlanName) return { text: "BUY NOW", disabled: false };
    
    if (activePlanName.toLowerCase() === title.toLowerCase())
      return { text: "ACTIVE PLAN", disabled: true };
    else return { text: "BUY NOW", disabled: false };
  };

  return (
    <div className={`member-pricing-card ${index === 1 ? "main" : ""}`}>
      {index === 1 && (
        <div className="tag-popular">
          <span className="tag">MOST POPULAR</span>
        </div>
      )}
      <h3 className="font-semibold">{title}</h3>
      <p className="mt-2 mb-4 color-black text-center">{desc}</p>
      <div className="flex items-center gap-2">
        <span className="line-through color-darkgrey">₹{oldPrice}</span>
        <span className={`tag ${theme}`}>
          SAVE {getSave(oldPrice, currPrice)}%
        </span>
      </div>
      <div className="flex items-end gap-1 my-4">
        <h4 className="mb-2">₹</h4>
        <h1 className="font-semibold">{currPrice}</h1>
        <h4 className="mb-2">/yr</h4>
      </div>
      <div className="w-full">
        {location.pathname.includes("banquet") ? (
          <Button
            theme={theme}
            size="md"
            rounded="xs"
            className={`w-full font-semibold ${
              BtnData().disabled && "cursor-default"
            }`}
            handleClick={() => !BtnData().disabled && sendMailToAdmin(title)}
            loading={loading}
          >
            {BtnData().text}
          </Button>
        ) : (
          <Button
            theme={theme}
            size="md"
            rounded="xs"
            className="w-full font-semibold"
            handleClick={() => navigate("/membership")}
          >
            JOIN NOW
          </Button>
        )}
      </div>
      <hr className="my-6 w-full" />
      <div className="w-full">
        <h4 className="font-semibold mb-4">Features</h4>
        <ul>
          {features.map((feature, index) => (
            <li
              key={index}
              className="flex gap-2 items-center my-2 font-medium"
            >
              <span className="text-xl scale-125">{feature.fIcon}</span>
              {feature.fName}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MemberPricingCard;
