import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import generateImageUrl from "../../../utils/generateImageUrl";

const CarousalComponent = ({ images, loading }) => {
  if (loading) return <PlaceholderCarousal />;
  return (
    <Carousel autoPlay interval={3000} infiniteLoop showThumbs={false}>
      {images &&
        images.map((image, index) => (
          <div key={index}>
            <img src={generateImageUrl(image)} alt="vendor-uploaded-data" />
          </div>
        ))}
    </Carousel>
  );
};
const PlaceholderCarousal = () => {
  return (
    <div className="carousel">
      <div className="skeleton-loader h-96 w-full"></div>
      <div className="my-4 flex justify-center gap-4">
        <div className="skeleton-loader h-16 w-16"></div>
        <div className="skeleton-loader h-16 w-16"></div>
        <div className="skeleton-loader h-16 w-16"></div>
        <div className="skeleton-loader h-16 w-16"></div>
      </div>
    </div>
  );
};

export default CarousalComponent;
