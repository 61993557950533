import React from "react";
import ListingItem from "./ListingItem";
import Pagination from "../../../common/Pagination";

const ListingResults = ({
  banquets,
  showPagination,
  page,
  setPage,
  totalPages,
  sponsoredBanquets,
}) => {
  return (
    <div className="listings-container">
      {sponsoredBanquets.map((banquet) => (
        <div key={banquet._id}>
          <ListingItem banquet={banquet} sponsored />
        </div>
      ))}
      {banquets.map((banquet) => (
        <div key={banquet._id}>
          <ListingItem banquet={banquet} />
        </div>
      ))}
      {showPagination && (
        <Pagination page={page} setPage={setPage} lastPage={totalPages} />
      )}
    </div>
  );
};

export default ListingResults;
