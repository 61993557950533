import { useState } from "react";
import { useNavigate } from "react-router";
import NavbarSearchbox from "./NavbarSearchbox";
import { GiModernCity } from "react-icons/gi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const NavbarBottom = () => {
  const navigate = useNavigate();

  const popularCities = [
    {
      name: "Delhi",
      popularLocalities: [
        { name: "Patparganj", value: "Patparganj" },
        { name: "Rohini", value: "Rohini Tehsil" },
        { name: "Sector 3", value: "Sector 3" },
        { name: "Patel Nagar", value: "Patel Nagar Tehsil" },
        {
          name: "Sector 3",
          value: "Sector 3",
        },
        { name: "Mayur Vihar", value: "Mayur Vihar" },
      ],
    },
    // {
    //   name: "New Delhi",
    //   popularLocalities: [
        
    //   ],
    // },
    {
      name: "Gurugram",
      popularLocalities: [
        { name: "Sector 13", value: "Sector 13" },
        { name: "Sector 48", value: "Sector 48" },
        { name: "Sector 69", value: "Sector 69" },
        {
          name: "Airforce Station",
          value: "Airforce Station Gurugram",
        },
      ],
    },
    {
      name: "Noida",
      popularLocalities: [
        { name: "Sector 22", value: "Sector 22" },
        { name: "Sector 31", value: "Sector 31" },
        { name: "Sector 51", value: "Sector 51" },
        { name: "Sector 62", value: "Sector 62" },
        { name: "Sector 74", value: "Sector 74" },
        {
          name: "Khora Colony",
          value: "TPM (The Pentecostal Mission Church) khora colony",
        },
      ],
    },
    {
      name: "Greater Noida",
      popularLocalities: [
        {
          name: "Knowledge Park 3",
          value: "Knowledge Park III",
        },
        {
          name: "Sector Omega",
          value: "Sector Omega",
        },
        // {
        //   name: "Surajpur",
        //   value: "Surajpur",
        // },
      ],
    },
    {
      name: "Ghaziabad",
      popularLocalities: [
        { name: "Sahibabad", value: "Sahibabad" },
        {
          name: "Indirapuram",
          value: "Indirapuram",
        },
        {
          name: "Vasundhara",
          value: "Vasundhara",
        },
        {
          name: "Sector 10",
          value: "Sector 10",
        },
        {
          name: "Govindpuram",
          value: "Govindpuram",
        },
        {
          name: "Nehru Nagar",
          value: "Nehru Nagar",
        },
      ],
    },
    {
      name: "Faridabad",
      popularLocalities: [
        {
          name: "New Industrial Township",
          value: "New Industrial Township",
        },
      ],
    },
  ];
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      <div className="navbar-bottom">
        <div className="show-on-mobile">
          <div className="flex justify-between items-center gap-4 px-8 py-4">
            <div
              className={
                showMobileMenu ? "menu-parent bg-red" : "menu-parent bg-blue"
              }
            >
              <GiModernCity
                size={18}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                className="cursor-pointer"
              />
            </div>
            <NavbarSearchbox className="mobile" />
          </div>
        </div>
        <nav
          className={`popular-cities ${!showMobileMenu && "hide-on-mobile"}`}
        >
          <ul className="flex gap-4 flex-wrap">
            {popularCities.map((city) => (
              <li key={city.name} className="popular-city">
                <div
                  className="flex gap-1 items-center cursor-pointer"
                  onClick={() => {
                    navigate(`/listings?city=${city.name}`);
                  }}
                >
                  {city.name} <MdOutlineKeyboardArrowDown />
                </div>
                <div className="popular-localities">
                  <ul className="flex flex-col gap-1">
                    {city.popularLocalities.map((locality) => (
                      <li
                        key={locality.value}
                        className="px-4 py-2"
                        onClick={() => {
                          navigate(
                            `/listings?locality=${locality.value}&city=${
                              city.name
                            }&date=${new Date()
                              .toISOString()
                              .slice(0, 10)
                              .replace(/-/g, "-")}&people=50`
                          );
                        }}
                      >
                        {locality.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
          <NavbarSearchbox className="hide-on-mobile" />
        </nav>
      </div>
    </>
  );
};

export default NavbarBottom;
