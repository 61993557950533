import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});

const ListingMap = ({ coordinates, name, address, city }) => {
  const DEFAULT_LATITUDE = 28.5355;
  const DEFAULT_LONGITUDE = 77.391;
  const latitude = coordinates?.lat ? coordinates.lat : DEFAULT_LATITUDE;
  const longitude = coordinates?.long ? coordinates.long : DEFAULT_LONGITUDE;
  if (!coordinates?.lat || !coordinates?.long)
    return <h5>Coordinates not uploaded</h5>;
  return (
    <MapContainer
      className="leaflet-map"
      center={[+latitude, +longitude]}
      // center={[11, 12]}
      zoom={12}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[+latitude, +longitude]} icon={icon}>
        <Popup>
          <h4>{name}</h4>
          <p style={{ margin: 0 }}>
            {address}, {city}
          </p>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default ListingMap;
