import { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Helmet } from "react-helmet";
import PassContext from "../../../utils/PassContext";
import api from "../../../utils/api";
import Button from "../../../common/Button";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMail,
} from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const inputPassword = useRef(null);

  useEffect(() => {
    if (inputPassword) {
      if (showPassword) inputPassword.current.type = "text";
      else inputPassword.current.type = "password";
    }
  }, [showPassword, inputPassword]);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    rememberMe: false,
    error: "",
  });

  useEffect(() => {
    //reset loginData
    setLoginData((prev) => ({
      ...prev,
      email: localStorage.getItem("rememberEmail") || "",
      password: localStorage.getItem("rememberPassword") || "",
      rememberMe: false,
      error: "",
    }));
  }, [location.pathname]);

  const { setLoggedUser } = useContext(PassContext);
  const handleLogin = async (role) => {
    const url = window.location.href;
    const redirect = url.split("/auth/login?redirect=")[1];
    try {
      const { data } = await api.post(`/${role}/login`, {
        data: {
          email: loginData.email,
          password: loginData.password,
        },
      });
      console.log(data);
      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      const decodedToken = jwtDecode(data.token);
      setLoggedUser({
        id: decodedToken.id,
        role: decodedToken.role,
      });
      if (redirect) navigate(redirect);
      else {
        if (role === "admin") navigate("/admin-home");
        else if (role === "banquet") navigate("/banquet-home");
        else navigate("/");
      }
    } catch (err) {
      console.log(err);
      setLoginData((prev) => ({
        ...prev,
        error: err?.response?.data?.message || "Something went wrong",
      }));
    }
  };

  const getHeadingText = () => {
    if (location.pathname === "/auth/admin-login") return "Admin Account";
    else if (location.pathname === "/auth/banquet-login")
      return "Banquet Account";
    else return "Login Account";
  };
  const getForgotPasswordLink = (pathname) => {
    if (pathname === "/auth/admin-login") return "/auth/admin-reset";
    else if (pathname === "/auth/banquet-login") return "/auth/banquet-reset";
    else return "/auth/reset-password";
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (loginData.rememberMe) {
          localStorage.setItem("rememberEmail", loginData.email);
          localStorage.setItem("rememberPassword", loginData.password);
        }
        if (location.pathname === "/auth/admin-login") handleLogin("admin");
        else if (location.pathname === "/auth/banquet-login")
          handleLogin("banquet");
        else handleLogin("user");
      }}
    >
      <Helmet>
        <title>Banquet Near Me | {getHeadingText()}</title>
        <meta
          name="description"
          content="Access your account by logging in. Enter your credentials and explore our services."
        />
      </Helmet>
      <h3>{getHeadingText()}</h3>
      <div className="mb-4">
        <h5 className="font-semibold mb-2">Email</h5>
        <div className="relative">
          <AiOutlineMail size={24} className="input-icon" />
          <input
            type="email"
            className="w-full with-icon"
            value={loginData.email}
            onChange={(e) => {
              const inputEmail = e.target.value;
              setLoginData((prev) => ({
                ...prev,
                email: inputEmail,
              }));
            }}
            required
          />
        </div>
      </div>
      <div className="mb-4">
        <h5 className="font-semibold mb-2">Password</h5>
        <div className="relative">
          {showPassword ? (
            <AiOutlineEyeInvisible
              className="cursor-pointer input-icon"
              onClick={(e) => {
                e.stopPropagation();
                setShowPassword(false);
              }}
              size={24}
            />
          ) : (
            <AiOutlineEye
              className="cursor-pointer input-icon"
              onClick={(e) => {
                e.stopPropagation();
                setShowPassword(true);
              }}
              size={24}
            />
          )}
          <input
            type="password"
            className="w-full with-icon"
            value={loginData.password}
            onChange={(e) => {
              const inputPassword = e.target.value;
              setLoginData((prev) => ({
                ...prev,
                password: inputPassword,
              }));
            }}
            ref={inputPassword}
            required
          />
        </div>

        <div className="flex justify-between gap-x-4 pass-options flex-wrap mt-1">
          <div className="flex gap-2 sm:gap-1">
            <input
              type="checkbox"
              checked={loginData.rememberMe}
              onChange={(e) => {
                setLoginData((prev) => ({
                  ...prev,
                  rememberMe: e.target.checked,
                }));
              }}
            />
            <span className="color-darkgrey">Remember Password</span>
          </div>
          <div>
            <Link
              className="color-black font-medium cursor-pointer"
              to={getForgotPasswordLink(location.pathname)}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
      <div>
        <Button
          className="font-bold w-full mt-2 text-lg"
          size="lg"
          rounded="full"
          theme="red"
          type="submit"
        >
          Login
        </Button>
      </div>
      <div
        className={`color-red text-sm text-end ${
          loginData.error ? "visible" : "invisible h-[1.25rem]"
        }`}
      >
        {loginData.error}
      </div>
      {!location.pathname !== "/auth/admin-login" && (
        <div className="color-black mt-4 text-center flex flex-wrap gap-x-2">
          Don't have an account?
          <Link
            to={
              location.pathname === "/auth/banquet-login"
                ? "/membership"
                : "/auth/register"
            }
            className="font-semibold cursor-pointer"
          >
            Create account
          </Link>
        </div>
      )}
      {location.pathname === "/auth/login" && (
        <>
          <div className="flex justify-center mt-1">
            <div className="bg-white font-medium color-darkgrey relative z-10 px-2">
              OR
            </div>
          </div>
          <hr className="relative top-[-0.7rem] mb-1" />
          <Button
            className="font-semibold w-full mt-2 text-lg flex justify-center items-center gap-x-4"
            size="lg"
            rounded="full"
            theme="blue"
            handleClick={() => {
              window.open(
                process.env.REACT_APP_BASE_API_URL + "/auth/google",
                "_self"
              );
            }}
          >
            <FcGoogle size={20} className="hide-on-sm-devices" /> Continue with
            Google
          </Button>
        </>
      )}
    </form>
  );
};

export default Login;
