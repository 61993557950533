import React from "react";
import Button from "../../../common/Button";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="home-banner">
      <div>
        <div>
          <h1>
            Find
            <span className="color-red"> Banquet </span>
            near by at best prices
          </h1>
        </div>
        <Link to="/listings?showTopBanquets=true">
          <Button theme="red" className="mt-4">
            Get Started!
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Banner;
