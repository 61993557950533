const generateImageUrl = (image) => {
  if (image) {
    return `${process.env.REACT_APP_BASE_API_URL}/${image.slice(15)}`;
  }
  return "/assets/Banquet-1";
};

export const generateImageUrlForBlogs = (image) => {
  if (image) {
    return `${process.env.REACT_APP_BASE_API_URL}/${image.slice(20)}`;
  }
  return "/assets/Banquet-1";
}

export default generateImageUrl;
