import { useState } from "react";
import { useNavigate } from "react-router";
import { MdSearch } from "react-icons/md";

const NavbarSearchbox = ({ className }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  return (
    <form
      className={`search-box ${className}`}
      onSubmit={(e) => {
        e.preventDefault();
        // get todays date in yyyy-mm-dd format
        const formattedDate = new Date()
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, "-");
        navigate(
          `/listings?locality=&city=${search}&date=${formattedDate}&people=50`
        );
      }}
    >
      <input
        placeholder="Search Cities"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button type="submit">
        <MdSearch />
      </button>
    </form>
  );
};

export default NavbarSearchbox;
