import { useContext } from "react";
import PassContext from "../../../utils/PassContext";
import ReviewItem, { PlaceholderReviewItem } from "./ReviewItem";
import AddReview from "./AddReview";
import GeneralisedReview from "./GeneralisedReview";

const ListingReviews = ({
  name,
  reviews,
  rating,
  banquetId,
  getListing,
  loading,
}) => {
  const { loggedUser } = useContext(PassContext);
  if (!rating) return null;
  return (
    <div className="py-8" id="reviews">
      <h3 className="font-semibold">Reviews of {name}</h3>
      <p className="color-darkgrey">Recommended by 95% of our customers</p>
      <GeneralisedReview rating={rating} loading={loading} />
      {/* <div className="all-review-images my-4">SHOW IMAGES HERE</div> */}
      {loggedUser && (
        <AddReview
          banquetId={banquetId}
          getListing={getListing}
          loading={loading}
        />
      )}
      <div>
        {loading ? (
          [1, 2, 3].map((i) => <PlaceholderReviewItem key={i} />)
        ) : reviews.length > 0 ? (
          reviews.map((review, index) => (
            <ReviewItem review={review} index={index} key={review._id} />
          ))
        ) : (
          <div className="my-8">
            <h4 className="font-semibold color-black">No Reviews Yet</h4>
            <p className="color-darkgrey text-sm mt-1">
              Be the first one to review
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingReviews;
