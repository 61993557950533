import React from "react";
import { Rating } from "react-simple-star-rating";
import dateFormatter from "../../../utils/dateFormatter";

const ReviewItem = ({ review, index }) => {

  const generateRandomColor = () => {
    const colors = [
      "red",
      "blue",
      "yellow",
      "green",
      "pink"
    ];
    return colors[index % colors.length];
  }

  return (
    <div className="my-8">
      <div className="flex gap-4 mb-2">
        <div className={`w-12 h-12 rounded-full bg-${generateRandomColor()} text-xl text-white flex items-center justify-center`}>
          {review?.user?.name?.toUpperCase()[0]}
        </div>
        <div className="mt-1">
          <div className="flex items-end gap-4">
            <h4 className="font-semibold color-black">{review?.user?.name}</h4>
            <p className="color-darkgrey text-sm mt-1">
              Reviewed on {dateFormatter(review.createdAt)}
            </p>
          </div>
          <div className="flex gap-4">
            <Rating
              initialValue={review.rating}
              readonly
              allowFraction
              emptyStyle={{ display: "flex" }}
              SVGstyle={{ display: "inline-block", marginBottom: 10 }}
              size={18}
            />
            <div className="color-darkgrey">
              {review?.rating?.toFixed(1)} out of 5
            </div>
          </div>
        </div>
      </div>
      <h5>{review.heading}</h5>
      <p className="color-darkgrey leading-5">{review.description}</p>
      <hr className="mt-8 h-[0.1rem] bg-grey-800" />
    </div>
  );
};

export const PlaceholderReviewItem = () => {
  return (
    <div className="my-8">
      <div className="flex gap-4 mb-2">
        <div className="w-12 h-12 rounded-full skeleton-loader"></div>
        <div>
          <div className="h-6 w-32 my-1 skeleton-loader"></div>
          <div className="h-4 w-48 my-1 skeleton-loader"></div>
        </div>
      </div>
      <div className="h-6 w-1/2 mb-1 skeleton-loader"></div>
      <p className="h-4 w-full my-1 skeleton-loader"></p>
      <p className="h-4 w-full my-1 skeleton-loader"></p>
      <p className="h-4 w-full my-1 skeleton-loader"></p>
      <hr className="mt-8 h-[0.1rem] bg-grey-800" />
    </div>
  );
};

export default ReviewItem;
