import { useState, useEffect, useContext } from "react";
import Button from "../../../common/Button";
import api from "../../../utils/api";
import PassContext from "../../../utils/PassContext";
import { AiOutlineStar, AiFillHeart, AiOutlineHeart } from "react-icons/ai";

const ListingHeadBtnGrp = ({ id }) => {
  const { loggedUser } = useContext(PassContext);

  const [saved, setSaved] = useState(false);
  const saveBanquet = async () => {
    try {
      const { data } = await api.get("/user/saveBanquet/" + id);
      console.log(data);
      setSaved(!saved);
    } catch (err) {
      console.log(err);
    }
  };
  const getSaved = async () => {
    try {
      const { data } = await api.get("/user/savedBanquets");
      setSaved(!!data.savedBanquets.find((banquet) => banquet._id === id)?._id);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loggedUser && loggedUser.role === "user") getSaved();
  }, [loggedUser]);

  const scrollToReviews = () => {
    if (document.getElementById("reviews"))
      window.scrollTo({
        top: document.getElementById("reviews").offsetTop - 100,
        behavior: "smooth",
      });
  };

  if (!loggedUser || loggedUser.role !== "user") return null;
  return (
    <>
      <Button
        rounded="none"
        theme="simple"
        className="flex gap-2 sm:gap-4 items-center hide-on-sm-devices"
        size="long"
        handleClick={scrollToReviews}
      >
        <AiOutlineStar />
        Write a Review
      </Button>
      <Button
        rounded="none"
        theme="simple"
        className="flex gap-2 sm:gap-4 items-center"
        size="long"
        handleClick={saveBanquet}
      >
        {saved ? (
          <>
            <AiFillHeart className="color-red" />
            Saved!
          </>
        ) : (
          <>
            <AiOutlineHeart />
            Save
          </>
        )}
      </Button>
    </>
  );
};

export default ListingHeadBtnGrp;
