import React from "react";

const ListingInfo = ({ info, loading }) => {
  if (loading) return <PlaceholderInfo />;

  return (
    <div id="details">
      {info && info.length > 0 ? (
        info.map((item, index) => (
          <div className="my-8" key={index}>
            <h3 className="font-semibold mb-2">{item.heading}</h3>
            <p className="color-darkgrey leading-5">{item.content}</p>
          </div>
        ))
      ) : (
        <div className="my-4">No information found</div>
      )}
    </div>
  );
};

const PlaceholderInfo = () => {
  return (
    <div id="details">
      {[1, 2, 3].map((i) => (
        <div className="my-8" key={i}>
          <div className="h-8 w-3/4 mb-2 skeleton-loader"></div>
          <p className="h-4 my-1 skeleton-loader"></p>
          <p className="h-4 my-1 skeleton-loader"></p>
          <p className="h-4 my-1 skeleton-loader"></p>
          <p className="h-4 my-1 skeleton-loader"></p>
          <p className="h-4 my-1 skeleton-loader"></p>
        </div>
      ))}
    </div>
  );
};

export default ListingInfo;
