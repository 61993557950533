import React from "react";

const ListingMoreInfo = ({
  moreInfo,
  facilities,
  pricePerPlate,
  capacity,
  occasions,
  loading,
}) => {
  if (loading) return <PlaceholderMoreInfo />;
  return (
    <div className="listing-more-info" id="faqs">
      <h3 className="font-bold">More information</h3>
      <hr className="mt-2" />
      <div className="my-8">
        <div className="flex gap-x-8 gap-y-2 flex-col sm:flex-row my-4">
          <div className="sm:w-1/3 font-semibold color-black">
            What is the price range of plates per person which includes
            beverages, food appetizers, main course, and desert items?
          </div>
          <div className="sm:w-2/3 font-medium color-red">
            {pricePerPlate === 0
              ? "On Demand"
              : `${moreInfo?.pax} (Average plate cost - ${pricePerPlate})`}
          </div>
        </div>
        <hr />
        <div className="flex gap-x-8 gap-y-2 flex-col sm:flex-row my-4">
          <div className="sm:w-1/3 font-semibold color-black">
            What all menus & catering options do you have?
          </div>
          <div className="sm:w-2/3 font-medium color-red flex gap-x-4 gap-y-2 flex-wrap">
            {moreInfo?.catering?.length ? (
              moreInfo?.catering?.map((catering) => (
                <span key={catering}>{catering}</span>
              ))
            ) : (
              <span>No catering options found</span>
            )}
          </div>
        </div>
        <hr />
        <div className="flex gap-x-8 gap-y-2 flex-col sm:flex-row my-4">
          <div className="sm:w-1/3 font-semibold color-black">
            How would you describe your event spaces?
          </div>
          <div className="sm:w-2/3 font-medium color-red flex gap-x-4 gap-y-2 flex-wrap">
            {moreInfo?.eventSpaces ? (
              moreInfo?.eventSpaces?.map((eventSpace) => (
                <span key={eventSpace}>{eventSpace}</span>
              ))
            ) : (
              <span>No event spaces found</span>
            )}
          </div>
        </div>
        <hr />
        <div className="flex gap-x-8 gap-y-2 flex-col sm:flex-row my-4">
          <div className="sm:w-1/3 font-semibold color-black">
            What're the facilities provided by the venue?
          </div>
          <div className="sm:w-2/3 font-medium color-red flex gap-x-4 gap-y-2 flex-wrap">
            {facilities ? (
              facilities?.map((facility) => (
                <span key={facility}>{facility}</span>
              ))
            ) : (
              <span>No facilities added</span>
            )}
          </div>
        </div>
        <hr />
        <div className="flex gap-x-8 gap-y-2 flex-col sm:flex-row my-4">
          <div className="sm:w-1/3 font-semibold color-black">
            What is the capacity of the venue?
          </div>
          <div className="sm:w-2/3 font-medium color-red">
            Can easily accomodate {capacity} guests
          </div>
        </div>
        <hr />
        <div className="flex gap-x-8 gap-y-2 flex-col sm:flex-row my-4">
          <div className="sm:w-1/3 font-semibold color-black">
            The venue is perfect for events like
          </div>
          <div className="sm:w-2/3 font-medium color-red flex gap-x-4 gap-y-2 flex-wrap">
            {occasions ? (
              occasions?.map((occasion) => (
                <span key={occasion} className="capitalize">{occasion}</span>
              ))
            ) : (
              <span>No data available</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PlaceholderMoreInfo = () => {
  return (
    <div className="listing-more-info" id="faqs">
      <h3 className="font-bold">More information</h3>
      <hr className="mt-2" />
      <div className="my-8">
        {[1, 2, 3].map((i) => (
          <div key={i}>
            <div className="flex gap-x-8 items-start gap-y-2 flex-col sm:flex-row my-4">
              <div className="sm:w-1/3">
                <p className="h-4 w-full my-1 skeleton-loader"></p>
                <p className="h-4 w-full my-1 skeleton-loader"></p>
              </div>
              <div className="sm:w-2/3">
                <p className="h-4 w-full my-1 skeleton-loader"></p>
                <p className="h-4 w-full my-1 skeleton-loader"></p>
                <p className="h-4 w-full my-1 skeleton-loader"></p>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListingMoreInfo;
