import React from "react";

const ListingMenu = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { name: "Photos", id: "photos" },
    { name: "Details", id: "details" },
    { name: "FAQs", id: "faqs" },
    { name: "Location", id: "map" },
    { name: "Reviews", id: "reviews" },
  ];

  function scrollTo(elementId) {
    const element = document.getElementById(elementId);
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <>
      <ul className="listingmenu">
        {tabs.map((tab, index) => {
          return (
            <li
              key={index}
              className={activeTab === tab.id ? "active" : ""}
              onClick={() => {
                setActiveTab(tab.id);
                scrollTo(tab.id);
              }}
            >
              {tab.name}
            </li>
          );
        })}
      </ul>
      <hr />
    </>
  );
};

export default ListingMenu;
