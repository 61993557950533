import toast, { toastConfig } from "react-simple-toasts";

const myToast = (msg, type) => {
  toastConfig({
    position: "bottom-right",
    duration: 8000,
  });
  toast(msg, { ...toastConfig, theme: type });
};

export default myToast;