import { useContext } from "react";
import { Link } from "react-router-dom";
import PassContext from "../../../utils/PassContext";
import ListingMap from "./ListingMap";
import Button from "../../../common/Button";

const ListingMapContainer = ({ listing, loading }) => {
  const { loggedUser } = useContext(PassContext);

  if (loading) return <div className="h-[60vh] skeleton-loader"></div>;

  if (loggedUser && listing.coordinates)
    return (
      <ListingMap
        coordinates={listing.coordinates}
        name={listing.name}
        address={listing.address}
        city={listing.city}
      />
    );

  return (
    <div className="location-locked">
      <h4 className="my-1">Feature locked</h4>
      <p className="text-center">
        Please login to view the location of the venue on the map.{" "}
      </p>
      <Link
        to={`/auth/login?redirect=${
          window.location.pathname + window.location.search
        }`}
      >
        <Button theme="red" rounded="full" className="mt-4">
          Login now and access all features
        </Button>
      </Link>
    </div>
  );
};

export default ListingMapContainer;
