import React from "react";
import "./Button.css";

const Button = ({
  children,
  handleClick,
  theme,
  rounded = "full",
  size = "md",
  type = "button",
  className,
  disabled = false,
}) => {
  return (
    <button
      className={`btn btn-${theme} btn-rounded-${rounded} btn-${size} ${className}`}
      onClick={handleClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
