import React from "react";
import { useLocation } from "react-router";
import userTerms from "./userTerms.json";
import banquetOwnerTerms from "./banquetownerTerms.json";

const Terms = ({ setShowTerms }) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="modal terms-card">
        <h4>Terms & Conditions</h4>
        <div>
          {pathname.includes("member") ? (
            <ol>
              {banquetOwnerTerms.terms.map((term) => (
                <li className="my-4">
                  <span className="font-semibold">{term.title}</span>
                  <ul>
                    {term.points.map((subTerm) => (
                      <li>{subTerm}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
          ) : (
            <ol>
              {userTerms.terms.map((term) => (
                <li className="my-4">
                  <span className="font-semibold">{term.title}</span>
                  <ul>
                    {term.points.map((subTerm) => (
                      <li>{subTerm}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
          )}
        </div>
      </div>
      <div className="backdrop" onClick={() => setShowTerms(false)} />
    </>
  );
};

export default Terms;
