import { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import api from "../../utils/api";
import BannerSearch from "../Home/components/BannerIntroSearch";
import { ListingsHalf, ListingsBoth } from "./components/ListingsBody";
import { useMetaTagsReplacer } from "../../utils/useMetaTagsReplacer";
import "./Listings.css";

const Listings = () => {
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const capitalize = (str) => {
    if (typeof str !== "string") return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const locality = capitalize(searchParams.get("locality"));
  const city = capitalize(searchParams.get("city"));
  const date =
    searchParams.get("date") ||
    new Date().toISOString().slice(0, 10).replace(/-/g, "-");
  const people = searchParams.get("people") || 50;

  const [banquetResults, setBanquetResults] = useState([]);
  const [banquetNearby, setBanquetNearby] = useState([]);
  const [banquetSponsored, setBanquetSponsored] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState("wedding");
  const getListings = async (sortBy) => {
    setLoading(true);
    try {
      const { data } = await api.post(
        `/user/searchBanquets?page=${page}&sortBy=${sortBy}&order=${order}`,
        {
          data: {
            locality: locality || "",
            city: city,
            people: people,
            occasion: selectedOccasion,
            date: date,
          },
        }
      );
      console.log(data);
      setBanquetResults(data.result);
      setBanquetNearby(data.suggestions || []);
      setBanquetSponsored(data.sponsored || []);
      setTotalPages(data.totalPages);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const getTopListings = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/user/topBanquets");
      setBanquetResults(data.topBanquetHalls);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const [offers, setOffers] = useState([]);
  const getOffers = async () => {
    try {
      const { data } = await api.get("/user/offers");
      console.log(data);
      setOffers(data.offers);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getOffers();
  }, []);

  const [sortBy, setSortBy] = useState("popularity");
  const [order, setOrder] = useState(1);
  useEffect(() => {
    const showTopBanquets = searchParams.get("showTopBanquets") || false;
    if (showTopBanquets === "true") getTopListings();
    else getListings(sortBy, order);
  }, [location, page, sortBy, order, selectedOccasion]);

  const getRespectiveMetaTags = (city) => {
    if (city) {
      if (city.toLowerCase() === "delhi")
        return {
          title: "Book Banquet hall Online According to requirement in Delhi",
          description:
            "banquet hall in Delhi is the perfect venue for your dream wedding. With its elegant and spacious design, suitable for large number of guests and provide a beautiful setting for your special day.",
          keywords:
            "banquet halls with rooms in delhi,banquet hall in rohini,party halls in south delhi,marriage banquet hall in delhi,book banquet hall in delhi,best wedding venues in delhi ncr,party halls in dwarka,wedding venues in chattarpur delhi",
        };
      else if (
        city.toLowerCase() === "gurugram" ||
        city.toLowerCase() === "gurgaon"
      )
        return {
          title:
            "Book Online Banquet Halls in Gurugram and Party Halls in Gurgaon",
          description:
            "Looking for the best banquet halls in Gurgaon? Look no further. Whether you're hosting a grand wedding or a small gathering, our best banquet halls guarantee an unforgettable experience.",
          keywords:
            "marriage halls in Gurgaon,best banquet halls in Gurgaon,wedding venues in Gurgaon with rooms,wedding banquets in Gurgaon,wedding halls in Gurgaon,banquet halls in Gurgaon for wedding,marriage hall in Gurgaon with price",
        };
      else if (city.toLowerCase() === "noida")
        return {
          title:
            "Planning a wedding with out-of-town guests? Our wedding venues in Noida",
          description:
            "With a range of options to choose from, our wedding venues in Noida cater to different budgets and preferences. Whether you're looking for a luxurious banquet hall or a more intimate space, we have the perfect venue for you.",
          keywords:
            "wedding venues in noida,banquet hall in noida,banquet in noida,wedding venues in noida with price,party halls in Noida,banquet halls in noida with price",
        };
      else if (city.toLowerCase() === "ghaziabad")
        return {
          title:
            "Banquet Near Me – Looking for premium Location in Ghaziabad for Wedding",
          description:
            "Our banquet halls in Ghaziabad are the perfect choice for your wedding celebration. Our marriage hall in Ghaziabad comes with a competitive price, ensuring that you get the best value for your money.",
          keywords:
            "banquet hall in Ghaziabad extension, banquet hall in Ghaziabad for birthday party, banquet hall in Ghaziabad for engagement, wedding venues in Ghaziabad with price, party halls in Ghaziabad, banquet halls in Ghaziabad with price",
        };
      else if (city.toLowerCase() === "faridabad")
        return {
          title:
            "Our banquet halls in Faridabad are the perfect choice for your special occasion.",
          description:
            "Our banquet halls in Faridabad are also ideal for birthday parties and engagement ceremonies. With a range of packages to choose from, you can find the perfect banquet hall in Faridabad for your event",
          keywords:
            "wedding venues in Faridabad with price,party halls in Faridabad,banquet halls in Faridabad with price,marriage halls in Faridabad,best banquet halls in Faridabad,wedding venues in Faridabad with rooms",
        };
      else if (city.toLowerCase() === "greater noida")
        return {
          title: "Find the Best Banquet Halls in Greater Noida for Your Event",
          description:
            "Our banquet halls in Greater Noida offer a variety of options for your special event. Whether you're planning a wedding, a birthday party, or a corporate event, we have the perfect venue for you.",
          keywords:
            "banquet halls in Greater Noida, wedding venues in Greater Noida, party halls in Greater Noida, marriage halls in Greater Noida, best banquet halls in Greater Noida, wedding venues in Greater Noida with rooms",
        };
    }
    return {
      title: "Banquet Near Me",
      description:
        "Online Banquet hall consultant in Delhi NCR | Wedding Venues | Party Halls Small spaces | Luxury halls | 5-star restaurant halls | Wedding Lawn in Ghaziabad Faridabad Gr. Noida Gurgaon",
      keywords:
        "banquet hall in delhi with price, best banquet halls in delhi with price, top 10 banquet halls in delhi, best wedding venues in chattarpur, small party halls in delhi, banquet halls south delhi",
    };
  };
  useMetaTagsReplacer(getRespectiveMetaTags(city));
  
  return (
    <div>
      <div className="listings-banner">
        <h1 className="color-white font-bold mb-4 text-center">
          Banquet Halls {locality || city}
        </h1>
        <p className="color-white text-center">
          Banquet Halls in {locality || city}: Check prices, menus,
          availability, request quotes and get the best deals for wedding
          banquets in a range of event venues, convention centers, private
          spaces, marriage halls and more to plan your perfect wedding
          functions: ceremony, sangeet, reception and everything else!
        </p>
      </div>
      <BannerSearch />
      {!!locality && !!city ? (
        <ListingsBoth
          city={city}
          locality={locality}
          sortBy={sortBy}
          setSortBy={setSortBy}
          order={order}
          setOrder={setOrder}
          offers={offers}
          banquetResults={banquetResults}
          loading={loading}
          banquetNearby={banquetNearby}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          selectedOccasion={selectedOccasion}
          setSelectedOccasion={setSelectedOccasion}
          banquetSponsored={banquetSponsored}
        />
      ) : (
        <ListingsHalf
          place={locality || city}
          sortBy={sortBy}
          setSortBy={setSortBy}
          order={order}
          setOrder={setOrder}
          offers={offers}
          loading={loading}
          banquets={
            banquetResults.length > 0
              ? banquetResults
              : banquetNearby.length > 0
              ? banquetNearby
              : []
          }
          selectedOccasion={selectedOccasion}
          setSelectedOccasion={setSelectedOccasion}
          banquetSponsored={banquetSponsored}
        />
      )}
    </div>
  );
};

export default Listings;
