import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import shortenNumber from "../../../utils/shortenNumber";
import Button from "../../../common/Button";
import ListingHeadBtnGrp from "./ListingHeadBtnGrp";
import { AiOutlineShareAlt } from "react-icons/ai";
import ShareModal from "./ShareModal";

function ShareBtn({ className, name, fullAddress }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <h4
        className={`flex gap-4 items-center color-darkgrey cursor-pointer ${className}`}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <AiOutlineShareAlt className="color-red text-2xl" />
        <span className="hide-on-mobile">Share</span>
      </h4>
      {showModal && (
        <ShareModal
          setShowModal={setShowModal}
          name={name}
          fullAddress={fullAddress}
        />
      )}
    </>
  );
}

const ListingHead = (props) => {
  const { id, name, address, city, pricePerPlate, capacity, rating, type } =
    props;

  if (props.loading) return <PlaceholderHead />;
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="flex gap-2 items-center">
            <h3 className="font-semibold">{name}</h3>
            <span className={`tag ${type}`}>{type}</span>
          </div>
          <p className="color-darkgrey capitalize">
            {address && `${address}, `} {city}
          </p>
        </div>
        <div className="flex gap-4 items-center hide-on-sm-devices">
          <ListingHeadBtnGrp id={id} />
        </div>
        <ShareBtn
          className="show-on-sm-devices"
          name={name}
          fullAddress={address ? `${address}, ${city}` : city}
        />
      </div>
      <div className="flex flex-wrap gap-4 justify-between my-4">
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex gap-4 items-center justify-between flex-wrap">
            <Button
              rounded="xs"
              theme="red"
              handleClick={() => props.setShowModal(true)}
            >
              Request Pricing
            </Button>
            <div className="show-on-sm-devices flex gap-4">
              <ListingHeadBtnGrp id={id} />
            </div>
          </div>
          <div className="listinghead-info-container">
            <div>
              <div className="flex gap-2 items-start color-darkgrey">
                <Rating
                  initialValue={rating?.total}
                  readonly
                  allowFraction
                  emptyStyle={{ display: "flex" }}
                  SVGstyle={{ display: "inline-block", marginBottom: 10 }}
                  size={18}
                />
                {shortenNumber(rating?.ratersCount)} Reviews
              </div>
              <h4
                style={{
                  transform: "translateY(-0.5rem)",
                }}
              >
                <span className="color-black">{rating?.total?.toFixed(1)}</span>{" "}
                out of 5
              </h4>
            </div>
            <div>
              <div className="color-darkgrey">Price per plate</div>
              <h4>
                {pricePerPlate === 0 ? "On Demand" : `From ₹${pricePerPlate}`}
              </h4>
            </div>
            <div>
              <div className="color-darkgrey">Number of Guests</div>
              <h4>{capacity}</h4>
            </div>
          </div>
        </div>
        <ShareBtn
          className="hide-on-sm-devices"
          name={name}
          fullAddress={`${address && `${address}, `} ${city}`}
        />
      </div>
    </div>
  );
};

export const PlaceholderHead = () => {
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="flex gap-2 items-center">
            <div className="skeleton-loader h-8 w-64"></div>
            <span className="tag regular skeleton-loader h-6 w-20"></span>
          </div>
          <p className="skeleton-loader h-4 my-2 w-48"></p>
        </div>
        <div className="flex gap-4 items-center hide-on-sm-devices">
          <div className="h-12 w-40 skeleton-loader"></div>
          <div className="h-12 w-40 skeleton-loader"></div>
        </div>
        <div className="skeleton-loader h-12 w-28 show-on-sm-devices"></div>
      </div>
      <div className="flex flex-wrap gap-4 justify-between my-4">
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex gap-4 items-center justify-between flex-wrap">
            <div className="h-12 w-36 skeleton-loader"></div>
            <div className="show-on-sm-devices flex gap-4">
              <div className="h-12 w-40 skeleton-loader"></div>
              <div className="h-12 w-40 skeleton-loader"></div>
            </div>
          </div>
          <div className="listinghead-info-container">
            <div>
              <div className="skeleton-loader h-4 w-32"></div>
              <div className="skeleton-loader h-7 mt-1 w-24"></div>
            </div>
            <div>
              <div className="skeleton-loader h-4 w-32"></div>
              <div className="skeleton-loader h-7 mt-1 w-24"></div>
            </div>
            <div>
              <div className="skeleton-loader h-4 w-32"></div>
              <div className="skeleton-loader h-7 mt-1 w-24"></div>
            </div>
          </div>
        </div>
        <div className="skeleton-loader h-12 w-28 hide-on-sm-devices"></div>
      </div>
    </div>
  );
};
export default ListingHead;
