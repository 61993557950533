import { useState } from "react";
import {
  IoMdArrowDropleft,
  IoMdArrowDropright,
  IoMdClose,
} from "react-icons/io";
import generateImageUrl from "../../../utils/generateImageUrl";
import Button from "../../../common/Button";

const CarousalDialog = ({ images, setShowBigCarousal }) => {
  const [currentImgIndex, setCurrrentImgIndex] = useState(0);
  return (
    <div className="img-container">
      <img
        src={generateImageUrl(images[currentImgIndex])}
        alt="banquet images"
      />
      <span
        className="left-btn slide-btns"
        onClick={(e) => {
          e.stopPropagation();
          let totalImages = images.length;
          if (currentImgIndex - 1 < 0) setCurrrentImgIndex(totalImages - 1);
          else setCurrrentImgIndex(currentImgIndex - 1);
        }}
      >
        <IoMdArrowDropleft />
      </span>
      <span
        className="right-btn slide-btns"
        onClick={(e) => {
          e.stopPropagation();
          let totalImages = images.length;
          if (currentImgIndex + 1 === totalImages) setCurrrentImgIndex(0);
          else setCurrrentImgIndex(currentImgIndex + 1);
        }}
      >
        <IoMdArrowDropright />
      </span>
      <div className="bottom-tray">
        {images.map((image, index) => (
          <div key={index} className="img-thumbnail">
            <img
              src={generateImageUrl(image)}
              alt="banquet images"
              onClick={() => setCurrrentImgIndex(index)}
            />
          </div>
        ))}
      </div>
      <div className="exit-carousel-dialog">
        <Button
          theme="red"
          rounded="none"
          handleClick={() => {
            setShowBigCarousal(false);
          }}
          className="flex items-center gap-2 font-semibold"
        >
          <IoMdClose /> EXIT
        </Button>
      </div>
    </div>
  );
};

export default CarousalDialog;
