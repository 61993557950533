import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import PassContext from "../../../utils/PassContext";

const SocialRedirection = () => {
  const navigate = useNavigate();
  const { setLoggedUser } = useContext(PassContext);
  const [searchParams] = useSearchParams();

  const [err, setErr] = useState("");

  useEffect(() => {
    const token = searchParams.get("token");
    const refreshToken = searchParams.get("refreshToken");
    if (
      !token ||
      !refreshToken ||
      token === "undefined" ||
      refreshToken === "undefined"
    ) {
      setErr("Invalid token or refresh token");
      return;
    }
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    const decodedToken = jwtDecode(token);
    if (decodedToken.id === "undefined" || decodedToken.role === "undefined") {
      setErr("Invalid token or refresh token - Code2");
      return;
    }
    setLoggedUser({
      id: decodedToken.id,
      role: decodedToken.role,
    });
    navigate("/");
  }, [searchParams, setLoggedUser]);
  return (
    <div>
      <h3>Redirecting...</h3>
      <p className="color-darkgrey">
        Please wait while we redirect you to our home page.
      </p>
      <div
        className={`color-red text-sm mt-4 text-end ${
          err ? "visible" : "invisible h-[1.25rem]"
        }`}
      >
        {err}
      </div>
    </div>
  );
};

export default SocialRedirection;
