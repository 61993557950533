import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import PassContext from "../utils/PassContext";
import { MdEmail, MdManageAccounts } from "react-icons/md";

const Navbar = () => {
  const navigate = useNavigate();
  const { loggedUser } = useContext(PassContext);

  return (
    <div className="navbar-top">
      <div onClick={() => navigate("/")} className="cursor-pointer">
        <img src="/assets/logo.svg" alt="logo" className="logo" />
      </div>
      {loggedUser?.role === "admin" || loggedUser?.role === "banquet" ? (
        <div className="flex justify-between items-center md:gap-4 lg:gap-8 hide-on-mobile">
          <div
            className="flex justify-between items-center gap-4 cursor-pointer"
            onClick={() => {
              if (loggedUser?.role === "admin") navigate("/admin-home");
              else if (loggedUser?.role === "banquet")
                navigate("/banquet-home");
              else navigate("/");
            }}
          >
            <MdManageAccounts className="color-yellow text-3xl" />
            <div>
              <h6 className="font-semibold">
                <span className="hide-on-mobile">View Panel</span>
              </h6>
              <p className="color-black">As viewed by {loggedUser?.role}</p>
            </div>
          </div>
          <div
            className="flex justify-between items-center gap-4 cursor-pointer hide-on-md-devices"
            onClick={() => navigate("/contact-us")}
          >
            <MdEmail className="color-yellow text-3xl" />
            <div>
              <h6 className="font-semibold">Contact Us:</h6>
              <p
                className="color-black"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("mailto:support@banquetnearme.com");
                }}
              >
                <span className="hide-on-mobile">E-mail:</span>{" "}
                support@banquetnearme.com
              </p>
              <p className="show-on-md-devices color-black">
                Mon to Sun (8am-7pm)
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center md:gap-4 lg:gap-8 hide-on-mobile">
          <Link
            className="flex justify-between items-center gap-4 cursor-pointer"
            to="/contact-us"
          >
            {/* <img src="/assets/call.svg" alt="call" /> */}
            <MdEmail className="color-yellow text-3xl" />
            <div>
              <h6 className="font-semibold">
                <span className="hide-on-mobile">Contact Us:</span>
              </h6>
              <p
                className="color-black"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("mailto:support@banquetnearme.com");
                }}
              >
                <span className="hide-on-mobile">E-mail:</span>{" "}
                support@banquetnearme.com
              </p>
              <p className="show-on-md-devices color-black">
                Mon to Sun (8am-7pm)
              </p>
            </div>
          </Link>
          <div className="flex justify-between items-center gap-4 hide-on-md-devices">
            <img src="/assets/clock.svg" alt="clock" />
            <div>
              <h6 className="font-semibold">Working Hours:</h6>
              <p className="color-black">Mon to Sun (8am-7pm)</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
