import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BannerIntro from "./components/BannerIntro";
import BannerIntroSearch from "./components/BannerIntroSearch";
// import QuickSelectCity from "./components/QuickSelectCity";
import TopBanquetList from "./components/TopBanquetList";
import Steps from "./components/Steps";
import Separator from "../../common/Separator";
import BannerDetails from "./components/BannerDetails";
import Blogs from "./components/Blogs";
import BanquetNearYou from "./components/BanquetsNearYou";
import "./Home.css";

const Home = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Banquet Near Me – Are you looking Banquet hall in Delhi NCR</title>
        <meta
          name="description"
          content="Online Banquet hall consultant in Delhi NCR | Wedding Venues | Party Halls Small spaces | Luxury halls | 5-star restaurant halls | Wedding Lawn in Ghaziabad Faridabad Gr. Noida Gurgaon"
        />
        <meta
          name="keywords"
          content="banquet hall in delhi with price, best banquet halls in delhi with price, top 10 banquet halls in delhi, best wedding venues in chattarpur, small party halls in delhi, banquet halls south delhi"
        />
      </Helmet>
      <BannerIntro />
      <BannerIntroSearch />
      {/* <QuickSelectCity /> */}
      <TopBanquetList />
      <BanquetNearYou lat={latitude} long={longitude} />
      <div className="px-32 mb-4">
        <Separator />
      </div>
      <Steps />
      <BannerDetails />
      <Blogs />
    </div>
  );
};

export default Home;
