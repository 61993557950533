import { useState, useEffect } from "react";
import api from "../../../utils/api";
import dateformatter from "../../../utils/dateFormatter";
import { Link } from "react-router-dom";
import Separator from "../../../common/Separator";

const Offers = () => {
  const [blogs, setBlogs] = useState([]);
  const getTheme = (index) => {
    const themes = ["red", "blue", "yellow"];
    if(index < 3) return themes[index];
    return themes[index % 3];
  };
  const getBlogs = async () => {
    try {
      const { data } = await api.get("/user/blogs");
      console.log(data);
      data.blogs = data.blogs.slice(0, 3);
      const withColorBlogs = data.blogs.map((blog, index) => {
        blog.color = getTheme(index);
        return blog;
      })
      setBlogs(withColorBlogs);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  return (
    <section className="home-blogs">
      <div className="flex items-center gap-10 offer-heading">
        <Link to="/blogs">
          <h1 className="w-[18rem]">Blogs for you</h1>
        </Link>
        <Separator theme="blue" size="md" className="w-[20rem]" />
      </div>
      <div className="articles-container">
        {blogs.map((article) => (
          <Link to={`/blogs/${article._id}`} key={article._id}>
            <div className={`article-card bg-${article.color}`}>
              <div className="flex items-center gap-5">
                <img
                  src="https://picsum.photos/200/300"
                  alt="article"
                  className="w-20 h-20 rounded-full"
                />
                <div>
                  <h5>Admin</h5>
                  <h5>{dateformatter(article.createdAt)}</h5>
                </div>
              </div>
              <div>
                <h3 className="limit-to-2-lines my-4" title={article.title}>
                  {article.title}
                </h3>
                <p className="limit-to-4-lines">
                  {removeTags(article.content)}
                </p>
                <button className="mt-4">Read Now</button>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Offers;
