import { useState } from "react";
import { useLocation } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import {
  FaSquareXTwitter,
  FaSquareFacebook,
  FaSquareWhatsapp,
  FaLink,
} from "react-icons/fa6";

const ShareModal = ({ setShowModal, name, fullAddress }) => {
  const location = useLocation();
  const [copied, setCopied] = useState(false);

  const properCasedAddress = (address) => {
    return address
      .split(" ")
      .filter((x) => x !== "")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getUrl = () => {
    return `https://banquetnearme.com/listings/${
      location.pathname.split("/")[2]
    }`;
  };
  const getEncodedText = () => {
    let string = `Check this banquet on BanquetNearMe.com\n\n${name}\n${properCasedAddress(
      fullAddress
    )}\n\n${getUrl()}`;
    return encodeURI(string);
  };
  return (
    <>
      <div className="modal share-card">
        <div className="font-semibold text-sm">SHARE THIS BANQUET</div>
        <div className="my-5">
          <h3>{name}</h3>
          <p className="color-darkgrey">{properCasedAddress(fullAddress)}</p>
        </div>
        <div className="flex gap-x-8 gap-y-4 flex-wrap justify-evenly">
          <a
            href={`https://api.whatsapp.com/send?text=${getEncodedText()}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaSquareWhatsapp
              className="h-[3rem] w-[3rem]"
              style={{
                color: "#25D366",
              }}
            />
          </a>
          {/* facebook doesn't allow prefilling of text */}
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${getUrl()}`}
            target="_blank"
            rel="noreferrer"
          >
            <FaSquareFacebook
              className="h-[3rem] w-[3rem]"
              style={{
                color: "#3b5998",
              }}
            />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${getEncodedText()}&hashtags=banquetnearme`}
            target="_blank"
            rel="noreferrer"
          >
            <FaSquareXTwitter className="h-[3rem] w-[3rem] color-black" />
          </a>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(getUrl());
              setCopied(true);
            }}
          >
            <FaLink
              className="h-[3rem] w-[3rem]"
              style={{
                color: copied ? "#10B981" : "#6B7280",
              }}
            />
          </div>
        </div>
      </div>
      <div className="backdrop" onClick={() => setShowModal(false)} />
    </>
  );
};

export default ShareModal;
