import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import PassContext from "../utils/PassContext";
import { MdEmail, MdHomeFilled } from "react-icons/md";

const NavbarSpecial = () => {
  const navigate = useNavigate();
  const { loggedUser } = useContext(PassContext);

  return (
    <div className="navbar-top">
      <div
        onClick={() => {
          if (loggedUser.role === "admin") navigate("/admin-home");
          else if (loggedUser.role === "banquet") navigate("/banquet-home");
          else navigate("/");
        }}
        className="cursor-pointer"
      >
        <img src="/assets/logo.svg" alt="logo" className="logo" />
      </div>
      <div className="flex justify-between items-center md:gap-4 lg:gap-8 hide-on-mobile">
        <Link
          className="flex justify-between items-center gap-4 cursor-pointer"
          to="/"
        >
          <MdHomeFilled className="color-yellow text-3xl" />
          <div>
            <h6 className="font-semibold">
              <span className="hide-on-mobile">View Home</span>
            </h6>
            <p className="color-black">As viewed by customers</p>
          </div>
        </Link>
        <Link
          className="flex justify-between items-center gap-4 cursor-pointer hide-on-md-devices"
          to="/contact-us"
        >
          <MdEmail className="color-yellow text-3xl" />
          <div>
            <h6 className="font-semibold">Contact Us:</h6>
            <p
              className="color-black"
              onClick={(e) => {
                e.stopPropagation();
                window.open("mailto:support@banquetnearme.com");
              }}
            >
              <span className="hide-on-mobile">E-mail:</span>{" "}
              support@banquetnearme.com
            </p>
            <p className="show-on-md-devices color-black">
              Mon to Sun (8am-7pm)
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NavbarSpecial;
