import React from "react";

const Offers = ({ offers }) => {
  return (
    <div>
      {offers.length > 0 && (
        <div className="flex flex-wrap gap-x-8 gap-y-4 my-4">
          {offers.map((offer, index) => (
            <span className="offer-box" key={index}>
              Get a {offer.name} with a minimum order of {offer.minOrder} (valid
              till {offer.validTill})
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Offers;
