import {
  AiOutlineTwitter,
  AiFillFacebook,
  // AiOutlineGoogle,
  AiOutlineInstagram,
} from "react-icons/ai";

const HeaderSocials = () => {
  return (
    <ul className="socials flex items-center gap-4 py-2">
      <li className="cursor-pointer">
        <a
          href="https://www.facebook.com/banquetnearme"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillFacebook />
        </a>
      </li>
      <li className="cursor-pointer">
        <a
          href="https://twitter.com/banquet_nearme"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineTwitter />
        </a>
      </li>
      {/* <li className="cursor-pointer">
        <a
          href="https://plus.google.com/+banquetnearme"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineGoogle />
        </a>
      </li> */}
      <li className="cursor-pointer">
        <a
          href="https://www.instagram.com/banquetnearme"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineInstagram />
        </a>
      </li>
    </ul>
  );
};

export default HeaderSocials;
