import { useLayoutEffect } from "react";

const resetData = {
  title: "Banquet Near Me",
  description:
    "Online Banquet hall consultant in Delhi NCR | Wedding Venues | Party Halls Small spaces | Luxury halls | 5-star restaurant halls | Wedding Lawn in Ghaziabad Faridabad Gr. Noida Gurgaon",
  keywords:
    "banquet hall in delhi with price, best banquet halls in delhi with price, top 10 banquet halls in delhi, best wedding venues in chattarpur, small party halls in delhi, banquet halls south delhi",
};

export const useMetaTagsReplacer = (props) => {
  const { title, description, keywords } = props;
  useLayoutEffect(() => {
    const titleElement = document.querySelector("title");
    const descriptionElement = document.querySelector(
      'meta[name="description"]'
    );
    const keywordsElement = document.querySelector('meta[name="keywords"]');

    if (titleElement) {
      titleElement.innerText = title;
    }
    if (descriptionElement) {
      descriptionElement.setAttribute("content", description);
    }
    if (keywordsElement) {
      keywordsElement.setAttribute("content", keywords);
    }

    return () => {
      if (titleElement) {
        titleElement.innerText = resetData.title;
      }
      if (descriptionElement) {
        descriptionElement.setAttribute("content", resetData.description);
      }
      if (keywordsElement) {
        keywordsElement.setAttribute("content", resetData.keywords);
      }
    };
  }, [title]);
};

useMetaTagsReplacer.defaultProps = resetData;
