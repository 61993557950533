import { TbSortAscending, TbSortDescending } from "react-icons/tb";

const ListingOptions = ({
  sortBy,
  setSortBy,
  order,
  setOrder,
  selectedOccasion,
  setSelectedOccasion,
}) => {
  const occasionsSuggestions = [
    {
      name: "Wedding",
      value: "wedding",
    },
    {
      name: "Corporate Event",
      value: "corporate event",
    },
    {
      name: "Engagement",
      value: "engagement",
    },
    {
      name: "Other",
      value: "all",
    },
  ];

  return (
    <div className="listing-options mb-8">
      <div className="flex justify-between items-center flex-wrap gap-4">
        <div className="flex flex-wrap gap-2">
          Looking for
          {occasionsSuggestions.map((occasion, index) => (
            <span
              key={index}
              className="cursor-pointer"
              onClick={() => setSelectedOccasion(occasion.value)}
            >
              <span
                className={`occasion-tag ${
                  occasion.value === selectedOccasion ? "added" : ""
                }`}
              >
                {occasion.name}
              </span>
            </span>
          ))}
        </div>
        <div className="flex items-center justify-end gap-2">
          <span className="font-bold">Sort by:</span>
          <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
            <option value="">Popularity</option>
            <option value="pricePerPlate">Price</option>
            <option value="rating.total">Rating</option>
            <option value="capacity">Capacity</option>
          </select>
          {
            <span
              className="cursor-pointer"
              onClick={() => setOrder(order === 1 ? -1 : 1)}
            >
              {order === 1 ? (
                <TbSortAscending size={24} />
              ) : (
                <TbSortDescending size={24} />
              )}
            </span>
          }
        </div>
      </div>
    </div>
  );
};

export default ListingOptions;
