import React from "react";
import { Rating } from "react-simple-star-rating";
import ReviewSpan from "./ReviewSpan";
import { GiFlexibleStar } from "react-icons/gi";
import { PiBagSimple } from "react-icons/pi";
import { TbAward, TbCalendarTime, TbReportMoney } from "react-icons/tb";

const GeneralisedReview = ({ rating, loading }) => {
  if(loading) return <PlaceholderGeneralisedReview />;

  const ratingsTemplate = [
    {
      name: "Quality of Service",
      key: "Qos",
      icon: <TbAward size={18} className="color-darkgrey" />,
    },
    {
      name: "Professionalism",
      key: "professionalism",
      icon: <PiBagSimple size={18} className="color-darkgrey" />,
    },
    {
      name: "Flexibility",
      key: "flexibility",
      icon: <GiFlexibleStar size={18} className="color-darkgrey" />,
    },
    {
      name: "Value",
      key: "value",
      icon: <TbReportMoney size={18} className="color-darkgrey" />,
    },
    {
      name: "Response Time",
      key: "responseTime",
      icon: <TbCalendarTime size={18} className="color-darkgrey" />,
    },
  ];
  
  return (
    <div className="flex gap-8 mt-4 mb-12 flex-col items-center sm:flex-row">
      <div className="border border-grey-700 h-[12.5rem] w-[12.5rem] p-4 flex flex-col justify-center items-center">
        <h2 className="text-center">{rating?.total?.toFixed(2)}</h2>
        <p className="text-center color-darkgrey mb-2">Out of 5.0</p>
        <div>
          <Rating
            initialValue={rating?.total}
            readonly
            allowFraction
            emptyStyle={{ display: "flex" }}
            SVGstyle={{ display: "inline-block", marginBottom: 10 }}
            size={18}
          />
        </div>
        <ReviewSpan rating={rating?.total} />
      </div>
      <div className="flex flex-wrap gap-x-8 gap-y-4">
        {ratingsTemplate.map((ratingTemplate) => (
          <div className="flex items-center gap-4" key={ratingTemplate.key}>
            {ratingTemplate.icon}
            <div>
              <div className="color-darkgrey leading-5">
                {ratingTemplate.name}
              </div>
              <div className="color-black font-semibold leading-5">
                {rating[ratingTemplate.key]?.toFixed(2)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PlaceholderGeneralisedReview = () => {
  return (
    <div className="flex gap-8 mt-4 mb-12 flex-col items-center sm:flex-row">
      <div className="h-32 w-32 skeleton-loader">
      </div>
      <div className="flex flex-wrap gap-x-8 gap-y-4 justify-center">
        {[1,2,3,4,5].map((i) => (
          <div className="h-6 w-36 skeleton-loader" key={i}>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GeneralisedReview;
