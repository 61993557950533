import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
// import { useWindowWidth } from "../../../utils/useWindowWidth";
import Button from "../../../common/Button";
import RequestPricingCard from "./RequestPricingCard";
import shortenNumber from "../../../utils/shortenNumber";
import generateImageUrl from "../../../utils/generateImageUrl";
import { TbAirConditioning, TbParking, TbDeviceSpeaker } from "react-icons/tb";
import {
  MdOutlineDining,
  MdOutlineBed,
  MdFilterTiltShift,
  MdOutlineWifi,
  MdOutlineCleaningServices,
  MdOutlineLocalLaundryService,
} from "react-icons/md";
import { SiAzurefunctions } from "react-icons/si";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { RiFireFill } from "react-icons/ri";

const ListingItem = ({ banquet, sponsored }) => {
  const navigate = useNavigate();
  const getFacilityIcon = (facility) => {
    if (facility.toLowerCase().includes("power")) return <SiAzurefunctions />;
    if (
      facility.toLowerCase().includes("ac") ||
      facility.toLowerCase().includes("air")
    )
      return <TbAirConditioning />;
    if (facility.toLowerCase().includes("parking")) return <TbParking />;
    if (facility.toLowerCase().includes("dj")) return <TbDeviceSpeaker />;
    if (
      facility.toLowerCase().includes("catering") ||
      facility.toLowerCase().includes("food") ||
      facility.toLowerCase().includes("kitchen") ||
      facility.toLowerCase().includes("dining") ||
      facility.toLowerCase().includes("buffet")
    )
      return <MdOutlineDining />;
    if (facility.toLowerCase().includes("room")) return <MdOutlineBed />;
    if (facility.toLowerCase().includes("wifi")) return <MdOutlineWifi />;
    if (
      facility.toLowerCase().includes("clean") ||
      facility.toLowerCase().includes("keep")
    )
      return <MdOutlineCleaningServices />;
    if (
      facility.toLowerCase().includes("wash") ||
      facility.toLowerCase().includes("laundry")
    )
      return <MdOutlineLocalLaundryService />;
    if (facility.toLowerCase().includes("hawan")) return <RiFireFill />;
    return <MdFilterTiltShift />;
  };

  // const windowWidth = useWindowWidth();

  // const getVisibleNumberOfFacilities = () => {
  //   if (windowWidth <= 640) return 3;
  //   if (windowWidth <= 840) return 2;
  //   // if (windowWidth <= 1024) return 3;
  //   if (windowWidth <= 1280) return 3;
  //   if (windowWidth <= 1440) return 4;
  //   return 6;
  // };
  const [showModal, setShowModal] = useState(false);
  const removeDetails = (string) => {
    // remove bracketed content
    return string.replace(/\(.*?\)/g, "");
  };

  const [currentImgIndex, setCurrrentImgIndex] = useState(0);

  return (
    <>
      <div
        className="listing-item cursor-pointer"
        onClick={() => {
          navigate("/listings/" + banquet._id);
        }}
      >
        <div className="img-container">
          <div className="relative">
            {sponsored ? (
              <div className="banquet-card-badge">Sponsored</div>
            ) : (
              new Date().getTime() - new Date(banquet.createdAt).getTime() <
                605000000 && (
                <div className="banquet-card-badge">Recently Added</div>
              )
            )}
            <img
              src={generateImageUrl(banquet.images[currentImgIndex])}
              alt={banquet.name}
            />
            <span
              className="left-btn slide-btns"
              onClick={(e) => {
                e.stopPropagation();
                let totalImages = banquet.images.length;
                if (currentImgIndex - 1 < 0)
                  setCurrrentImgIndex(totalImages - 1);
                else setCurrrentImgIndex(currentImgIndex - 1);
              }}
            >
              <IoMdArrowDropleft />
            </span>
            <span
              className="right-btn slide-btns"
              onClick={(e) => {
                e.stopPropagation();
                let totalImages = banquet.images.length;
                if (currentImgIndex + 1 === totalImages) setCurrrentImgIndex(0);
                else setCurrrentImgIndex(currentImgIndex + 1);
              }}
            >
              <IoMdArrowDropright />
            </span>
          </div>
          <Button
            rounded="none"
            theme="red"
            className="show-on-md-devices w-full font-medium"
            handleClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
            }}
          >
            Request Pricing
          </Button>
        </div>
        <div className="w-full">
          <h2 className="font-bold">{banquet.name}</h2>
          <div className="flex gap-x-4 flex-wrap-reverse color-darkgrey">
            <div className="flex gap-2">
              <Rating
                initialValue={banquet.rating.total}
                readonly
                allowFraction
                emptyStyle={{ display: "flex" }}
                SVGstyle={{ display: "inline-block", marginBottom: 10 }}
                size={20}
              />
              {shortenNumber(banquet.rating.ratersCount)} ratings
            </div>
            <div>|</div>
            <div className="capitalize">
              {banquet.locality === banquet.city
                ? banquet.locality
                : `${banquet.locality && `${banquet.locality}, `} ${
                    banquet.city
                  }`}
            </div>
          </div>
          <p className="color-darkgrey leading-5">{banquet.special}</p>
          {banquet?.facilities && (
            <div className="flex gap-x-4 gap-y-2 my-4 flex-wrap">
              {banquet.facilities
                .slice(0, 10)
                // .slice(0, getVisibleNumberOfFacilities())
                .map((facility, index) => (
                  <span key={index} className="flex items-center gap-2">
                    {getFacilityIcon(facility)}
                    {removeDetails(facility)}
                  </span>
                ))}
              {/* <span className="font-bold">
                +{banquet?.facilities?.length - getVisibleNumberOfFacilities()}{" "}
                more
              </span> */}
            </div>
          )}
          <div className="flex flex-wrap gap-4 justify-between items-center my-4">
            <div className="flex gap-8">
              <div>
                <div className="font-semibold color-grey2">Price per plate</div>
                <h3 className="color-red">{banquet.pricePerPlate}</h3>
              </div>
              <div>
                <div className="font-semibold color-grey2">Guests</div>
                <h3 className="color-darkgrey">{banquet.capacity}</h3>
              </div>
            </div>
            <Button
              rounded="none"
              theme="red"
              className="hide-on-md-devices"
              handleClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
              }}
            >
              Request Pricing
            </Button>
          </div>
        </div>
      </div>
      <hr />
      {showModal && (
        <RequestPricingCard
          setShowModal={setShowModal}
          banquet={banquet}
          modal={true}
        />
      )}
    </>
  );
};

export const PlaceholderListingItem = () => {
  return (
    <>
      <div className="listing-item placeholder">
        <div className="img-container">
          <div className="placeholder-img skeleton-loader"></div>
        </div>
        <div className="w-full">
          <div className="h-10 w-1/2 skeleton-loader"></div>
          <div className="flex gap-4 my-4">
            <div className="h-4 w-24 skeleton-loader"></div>
            <span className="h-4 w-32 skeleton-loader"></span>
          </div>
          <p className="h-4 w-full my-2 skeleton-loader"></p>
          <p className="h-4 w-full my-2 skeleton-loader"></p>
          <p className="h-4 w-full my-2 skeleton-loader"></p>
          {
            <div className="flex gap-4 my-4">
              {[1, 2, 3, 4].map((item) => (
                <span key={item} className="h-4 w-16 skeleton-loader"></span>
              ))}
            </div>
          }
          <div className="flex flex-wrap gap-4 justify-between items-center my-4">
            <div className="flex gap-8">
              <div>
                <div className="h-4 w-20 skeleton-loader"></div>
                <div className="h-8 w-12 mt-2 skeleton-loader"></div>
              </div>
              <div>
                <div className="h-4 w-20 skeleton-loader"></div>
                <div className="h-8 w-12 mt-2 skeleton-loader"></div>
              </div>
            </div>
            <div className="h-12 w-36 skeleton-loader hide-on-md-devices"></div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ListingItem;
