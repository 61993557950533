import { Outlet, useLocation } from "react-router-dom";
import "./Auth.css";

const AuthFlowLayout = () => {
  const location = useLocation();
  return (
    <div
      className={`banner-form-page-layout ${location.pathname.split("/")[2]}`}
    >
      <div className="banner-tagline">
        <h1 className="text-white">
          Celebrate in style with{" "}
          <span className="color-yellow font-bold"> the best Banquet</span> options in
          your area.
        </h1>
        <p className="text-white font-medium my-8 text-lg">
          Sign up with your phone number and get exclusive access to discounts
          and savings on <span className="color-yellow">Banquet Near Me</span>{" "}
          and with our many travel partners.
        </p>
      </div>
      <div className={`auth-card ${location.pathname.split("/")[2]}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default AuthFlowLayout;
