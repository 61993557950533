import React from "react";
import { Link } from "react-router-dom";

const FooterLists = ({ className = "" }) => {
  const footerLists = [
    {
      heading: "Useful Links",
      children: [
        {
          name: "About Us",
          link: "/about-us",
        },
        {
          name: "News Blog",
          link: "/blogs",
        },
        {
          name: "Support",
          link: "/contact-us",
        },
        {
          name: "Membership Pricing",
          link: "/member-pricing",
        },
      ],
    },
    {
      heading: "Terms & Conditions",
      children: [
        {
          name: "Responsible Disclosure",
          link: "/responsible-disclosure",
        },
        {
          name: "Refund Policy",
          link: "/refund-policy",
        },
        {
          name: "Terms & Conditions",
          link: "/terms-conditions",
        },
        {
          name: "Privacy Policy",
          link: "privacy-policy"
        }
      ],
    },
  ];
  return (
    <div className={`flex gap-4 justify-between footer-list ${className}`}>
      {footerLists.map((list, index) => (
        <div className="footer-list-item" key={index}>
          <h4 className="uppercase pb-2">{list.heading}</h4>
          <ul>
            {list.children.map((item) => (
              <Link to={item.link} key={item.name}>
                <li className="py-2 color-darkgrey cursor-pointer">
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FooterLists;
