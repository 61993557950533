import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWindowWidth } from "../utils/useWindowWidth";
import PassContext from "../utils/PassContext";
import Socials from "./Socials";
import { FaUser } from "react-icons/fa";
import {
  MdOutlineCardMembership,
  MdOutlineEditNote,
  // MdOutlineAdminPanelSettings,
} from "react-icons/md";

const Header = () => {
  const navigate = useNavigate();
  const { loggedUser, setLoggedUser } = useContext(PassContext);
  const handleLogout = () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      const lastRole = loggedUser.role;
      setLoggedUser(null);
      if (lastRole === "admin") navigate("/auth/admin-login");
      else if (lastRole === "banquet") navigate("/auth/banquet-login");
      else navigate("/auth/login");
    } catch (err) {
      console.log(err);
    }
  };
  const windowWidth = useWindowWidth();
  if (windowWidth <= 630)
    return (
      <header className="flex header-func">
        {loggedUser ? (
          <>
            <Link to="/membership">
              <div className="flex items-center gap-2 cursor-pointer">
                <MdOutlineCardMembership />
                Become Member
              </div>
            </Link>
            <div className="logged-in-container mr-2 flex items-center">
              <div className="flex items-center gap-2">
                <FaUser />
                Logged In
              </div>
              <ul>
                {loggedUser.role === "user" && (
                  <>
                    <li
                      className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                      onClick={() => {
                        navigate("/edit-profile");
                      }}
                    >
                      Edit profile
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                      onClick={() => {
                        navigate("/saved-banquets");
                      }}
                    >
                      Saved banquets
                    </li>
                  </>
                )}
                {loggedUser.role === "banquet" && (
                  <li
                    className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                    onClick={() => {
                      navigate("/auth/banquet-reset");
                    }}
                  >
                    Reset Password
                  </li>
                )}
                <li
                  className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  Log out
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <Link to="/auth/login">
              <div className="flex items-center gap-2 cursor-pointer">
                <FaUser />
                Log in
              </div>
            </Link>
            <Link to="/auth/register">
              <div className="flex items-center gap-2 cursor-pointer">
                <MdOutlineEditNote />
                Register Now
              </div>
            </Link>
            <Link to="/auth/banquet-login">
              <div className="flex items-center gap-2 cursor-pointer">
                <MdOutlineCardMembership />
                For Members
              </div>
            </Link>
          </>
        )}
      </header>
    );

  return (
    <header className="flex justify-between">
      <Socials />
      <div className="flex header-func">
        {loggedUser ? (
          <>
            <Link to="/membership">
              <div className="flex items-center gap-2 cursor-pointer">
                <MdOutlineCardMembership />
                Become Member
              </div>
            </Link>
            <div className="logged-in-container mr-2 flex items-center">
              <div className="flex items-center gap-2">
                <FaUser />
                Logged In
              </div>
              <ul>
                {loggedUser.role === "user" && (
                  <>
                    <li
                      className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                      onClick={() => {
                        navigate("/edit-profile");
                      }}
                    >
                      Edit profile
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                      onClick={() => {
                        navigate("/saved-banquets");
                      }}
                    >
                      Saved banquets
                    </li>
                  </>
                )}
                {loggedUser.role === "banquet" && (
                  <li
                    className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                    onClick={() => {
                      navigate("/auth/banquet-reset");
                    }}
                  >
                    Reset Password
                  </li>
                )}
                <li
                  className="px-4 py-2 hover:bg-slate-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  Log out
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <Link to="/auth/login">
              <div className="flex items-center gap-2 cursor-pointer">
                <FaUser />
                Log in
              </div>
            </Link>
            <Link to="/auth/register">
              <div className="flex items-center gap-2 cursor-pointer">
                <MdOutlineEditNote />
                Register Now
              </div>
            </Link>
            <Link to="/auth/banquet-login">
              <div className="flex items-center gap-2 cursor-pointer">
                <MdOutlineCardMembership />
                For Members
              </div>
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
