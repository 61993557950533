import React from "react";
import { RxCheck, RxCross2 } from "react-icons/rx";
import MemberPricingCard from "./components/MemberPricingCard";

const PricingPage = () => {
  const pricingData = [
    // {
    //   title: "Basic",
    //   desc: "Everything you need to boost your business",
    //   currPrice: "0",
    //   oldPrice: "1000",
    //   features: [
    //     {
    //       fName: "Listing",
    //       fIcon: <RxCheck className="color-green" />,
    //     },
    //     {
    //       fName: "Super Listing",
    //       fIcon: <RxCross2 className="color-red" />,
    //     },
    //     {
    //       fName: "Sponsored/Suggested",
    //       fIcon: <RxCross2 className="color-red" />,
    //     },
    //     {
    //       fName: "10 Enquiries",
    //       fIcon: <RxCheck className="color-orange" />,
    //     },
    //     {
    //       fName: "20% Service Charge",
    //       fIcon: <RxCheck className="color-orange" />,
    //     },
    //     {
    //       fName: "Social Media Marketing",
    //       fIcon: <RxCross2 className="color-red" />,
    //     },
    //     {
    //       fName: "Personal Website",
    //       fIcon: <RxCross2 className="color-red" />,
    //     },
    //   ],
    // },
    {
      title: "Standard",
      desc: "Level-up with more powerful features",
      currPrice: "10,000",
      oldPrice: "12,000",
      features: [
        {
          fName: "Listing",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Super Listing",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Sponsored/Suggested",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Quotation Software",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "100 Enquiries",
          fIcon: <RxCheck className="color-yellow" />,
        },
        {
          fName: "10% Service Charge",
          fIcon: <RxCheck className="color-yellow" />,
        },
        {
          fName: "Social Media Marketing",
          fIcon: <RxCross2 className="color-red" />,
        },
        {
          fName: "Personal Website",
          fIcon: <RxCross2 className="color-red" />,
        },
      ],
    },
    {
      title: "Premium",
      desc: "Enjoy all benefits & guaranteed results",
      currPrice: "25,000",
      oldPrice: "28,000",
      features: [
        {
          fName: "Listing",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Super Listing",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Quotation Software",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Sponsored/Suggested",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "300 Enquiries",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "7% Service Charge",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Social Media Marketing",
          fIcon: <RxCheck className="color-green" />,
        },
        {
          fName: "Personal Website",
          fIcon: <RxCheck className="color-green" />,
        },
      ],
    },
  ];
  return (
    <div className="page-layout">
      <h2 className="text-center">Best Banquet Listing Pricing</h2>
      <p className="text-center">
        No matter your budget – we have something for everyone. Get your banquets listed now
        on
        <span className="color-red font-medium"> BanquetNearMe.com</span>
      </p>
      <div className="flex justify-center gap-x-6 gap-y-12 my-16 flex-wrap">
        {pricingData.map((pricing, index) => (
          <MemberPricingCard key={index} index={index} {...pricing} />
        ))}
      </div>
    </div>
  );
};

export default PricingPage;
