import { PlaceholderListingItem } from "./ListingItem";
import ListingsContainer from "./ListingsContainer";
import Offers from "./Offers";
import ListingOptions from "./ListingOptions";

const ListingsNotFound = () => {
  return (
    <div className="my-8 flex items-center gap-4 flex-wrap justify-center sm:flex-nowrap">
      <img
        src="/assets/vision.svg"
        alt="vision"
        style={{ width: "20rem", height: "20rem" }}
      />
      <div>
        <h3 className="my-2">
          We’re sorry, but we have no banquets in this location.
        </h3>
        <p className="color-black leading-5">
          This is because we are still expanding our network of banquets to
          cover more destinations around the world. We appreciate your patience
          and interest in our service.
        </p>
      </div>
    </div>
  );
};

export const ListingsHalf = ({
  place,
  sortBy,
  setSortBy,
  order,
  setOrder,
  offers,
  loading,
  banquets,
  selectedOccasion,
  setSelectedOccasion,
  banquetSponsored,
}) => {
  return (
    <div className="page-layout listings-page">
      <div className="breadcrumbs mb-4">{"India / " + place + " Banquets"}</div>
      <ListingOptions
        sortBy={sortBy}
        setSortBy={setSortBy}
        order={order}
        setOrder={setOrder}
        selectedOccasion={selectedOccasion}
        setSelectedOccasion={setSelectedOccasion}
      />
      <h3 className="font-bold">
        Showing Banquets in {place} ({banquets.length} Banquet Halls)
      </h3>
      <Offers offers={offers} />
      {loading ? (
        <div className="listings-container">
          {[1, 2, 3, 4, 5].map((i) => (
            <PlaceholderListingItem key={i} />
          ))}
        </div>
      ) : banquets.length > 0 ? (
        <div className="my-16">
          <ListingsContainer
            banquets={banquets}
            showPagination={false}
            sponsoredBanquets={banquetSponsored}
          />
        </div>
      ) : (
        <ListingsNotFound />
      )}
    </div>
  );
};
export const ListingsBoth = ({
  city,
  locality,
  sortBy,
  setSortBy,
  order,
  setOrder,
  offers,
  loading,
  banquetResults,
  banquetNearby,
  page,
  setPage,
  totalPages,
  selectedOccasion,
  setSelectedOccasion,
  banquetSponsored,
}) => {
  return (
    <div className="page-layout listings-page">
      <div className="breadcrumbs mb-4">
        {city && locality
          ? "India / " + city + " / " + locality + " Banquets"
          : city
          ? "India / " + city + " Banquets"
          : "India / " + locality + " Banquets"}
      </div>
      <ListingOptions
        sortBy={sortBy}
        setSortBy={setSortBy}
        order={order}
        setOrder={setOrder}
        selectedOccasion={selectedOccasion}
        setSelectedOccasion={setSelectedOccasion}
      />
      <h3 className="font-bold">
        Banquets in {locality} {city} ({banquetResults.length} Banquet Halls)
      </h3>
      <Offers offers={offers} />
      {loading ? (
        <div className="listings-container">
          {[1, 2, 3, 4, 5].map((i) => (
            <PlaceholderListingItem key={i} />
          ))}
        </div>
      ) : banquetResults.length > 0 ? (
        <ListingsContainer
          banquets={banquetResults}
          showPagination={true}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          sponsoredBanquets={banquetSponsored}
        />
      ) : (
        <ListingsNotFound />
      )}
      {loading ? (
        <div className="listings-container">
          {[1, 2, 3, 4, 5].map((i) => (
            <PlaceholderListingItem key={i} />
          ))}
        </div>
      ) : (
        banquetNearby.length > 0 && (
          <div className="my-16">
            <h3 className="font-bold">
              Showing Banquets in {city} ({banquetNearby.length} Banquet Halls)
            </h3>
            <ListingsContainer
              banquets={banquetNearby}
              sponsoredBanquets={banquetSponsored}
              showPagination={false}
            />
          </div>
        )
      )}
    </div>
  );
};
